import Modal from "../Modals/Modal";
import { useState } from "react";
import { Link } from "react-router-dom";
import ExitModal from "../Modals/ExitModal";
import { useTranslation } from "react-i18next";

const UserDropdown = ({ name, showSingleText }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const handleClick = () => {
    if (!showSingleText) {
      setIsOpen(!isOpen);
    }
  }
  const { t } = useTranslation();

  return (
    <div className="group h-4 relative inline-block" onClick={handleClick}>
      <div className="hover:text-green">
        <button className="cursor-pointer outline-none font-medium 2xl:text-lg lg:text-sm mr-2">
          <span className="inline-block mr-2">{name}</span>
          {!showSingleText && (
            <span
              style={{
                transform: "rotate(180deg)",
                display: "inline-block",
              }}
            >
              &#8963;
            </span>
          )}
        </button>
      </div>
      <div
        className={
          "group-hover:flex flex-col z-10 absolute top-10 right-0 bg-selectGray 2xl:w-44 h-28 rounded" +
          (isOpen ? " flex" : " hidden")
        }
      >
        <Link
          to="/profile"
          className="block text-center text-blue border-b-2 border-selectGray w-36 mx-auto py-4 font-bold text-sm hover:text-green"
        >
          {t("profile")}
        </Link>
        <button
          className="hover:text-red text-blue py-4 font-bold text-sm"
          onClick={openModal}
        >
          {t("logout")}
        </button>
      </div>
      <Modal show={isModalOpen} onClose={closeModal}>
        <ExitModal onClick={closeModal} />
      </Modal>
    </div>
  );
};

export default UserDropdown;
