import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./components/LoginPage/LoginPage";
import MainContent from "./components/MainContent/MainContent";
import InvestmentsContent from "./components/InvestmentsContent/InvestmentsContent";
import SignInForm from "./components/LoginForms/SignInForm";
import SignUpForm from "./components/LoginForms/SignUpForm";
import MainLayout from "./components/MainLayout/MainLayout";
import UserProfile from "./components/UserProfile/UserProfile";
import CallService from "./services/CallService";
import i18next from "i18next";
import Landing from "./components/Landing/Landing";
import SupportUser from "./components/Support/SupportUser";
import SupportGuest from "./components/Support/SupportGuest";

function App() {
  const { token } = localStorage;

  useEffect(() => {
    (async () => {
      const data = await CallService.GetUserInfo();
      if (data === "Unauthorized") {
        localStorage.setItem("token", "");
        localStorage.removeItem("token");
        localStorage.removeItem("firstName");
        localStorage.removeItem("id");
        localStorage.removeItem("email");
      }
      if(typeof data  === 'object' && data.hasOwnProperty('firstName')){
          localStorage.setItem("firstName", data.firstName);
      }
    })();
    if (localStorage.getItem("i18nextLng") === "") {
      i18next.changeLanguage("ru");
      localStorage.setItem("i18nextLng", "ru");
    }
    if (localStorage.getItem("i18nextLng") === null) {
      localStorage.setItem("i18nextLng", "ru");
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            !token ? (
              <Landing />
            ) : (
              <MainLayout>
                <MainContent />
              </MainLayout>
            )
          }
        />

        <Route
          path="login"
          element={
            <LoginPage>
              <SignInForm />
            </LoginPage>
          }
        />
        <Route
          path="/registration"
          element={
            <LoginPage>
              <SignUpForm />
            </LoginPage>
          }
        />

        <Route
          path="/investments"
          element={
            <MainLayout>
              <InvestmentsContent />
            </MainLayout>
          }
        />
        <Route
          path="/profile"
          element={
            <MainLayout>
              <UserProfile />
            </MainLayout>
          }
        />
        {token
          ? <Route
            path="/support"
            element={
              <MainLayout>
                <SupportUser />
              </MainLayout>
            }
          />
          : <Route
            path="/support"
            element={
              <LoginPage>
                <SupportGuest />
              </LoginPage>
            }
          />
        }
      </Routes>
    </BrowserRouter>
  );
}

export default App;
