import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { conventionTabs } from "../../../static/consts";
import CustomerHandling from "./CustomerHandling";
import PositionClient from "./PositionClient";
import PrivacyPolicy from "./PrivacyPolicy";

const Сonvention = () => {
  const { t } = useTranslation();
  const [view, setView] = useState(1)

  return (
    <>
      <div className=" mt-1 sm:mt-4 mb-2 sm:mb-4 flex items-center gap-2 sm:gap-4">
        {conventionTabs
          .map((tab, index) => (
            <div
              key={tab.id}
              onClick={() => setView(tab.view)}
              className={`text-xs sm:text-base cursor-pointer px-0 pb-2 sm:pb-4 border-b-2 border-transparent capitalize sm:uppercase ${view === tab.view && "border-indigo-600"
                }`}
            >
              {tab.name}
            </div>
          ))}
      </div>

      {
        view === 1 && <CustomerHandling />
      }
      {
        view === 2 && <PrivacyPolicy />
      }

      {
        view === 3 && <PositionClient />
      }


    </>
  );
};

export default Сonvention;
