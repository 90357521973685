import { useTranslation } from "react-i18next";
import SupportGuestForm from "./SupportGuestForm";

const Support = () => {
  const { t } = useTranslation();

  return (
      <div className="w-4/6 mx-auto mb-8">
      <h1 className="text-5xl mt-4 font-bold text-green pb-4">
          {t("support_title")}
      </h1>
      <p>{t("support_description")}</p>
      <SupportGuestForm />
    </div>
  );
};

export default Support;
