import React from "react";
import OnlyProject from "../../static/landing-images/onlyProject.png";
import ButtonLink from "./LandingButton/ButtonLink";
import { useTranslation } from "react-i18next";

const LandingProject = () => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-between relative sm:mt-36 mt-10">
      <div className="sm:w-6/12 w-full relative z-10">
        <h3 className="sm:text-4xl text-2xl font-bold sm:text-left text-center">
          {t("landing_project")}
        </h3>
        <p className="sm:mt-7 mt-3 sm:text-lg text-sm sm:text-left text-center">
          {t("landing_project_body")}
        </p>
        <div className="sm:mt-8 mt-5">
          <ButtonLink link="/login">{t("become_a_customer")}</ButtonLink>
        </div>
      </div>

      <div className="sm:block hidden">
        <img className="object-cover h-full" src={OnlyProject} alt="" />
      </div>
    </div>
  );
};

export default LandingProject;
