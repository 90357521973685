import axios from "axios";

class CallService {
  static API() {
    return axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
  static async SignUp(params) {
    try {
      const { data } = await this.API().post("/user/sign-up", params);
      return data;
    } catch (e) {
      return e.response.data;
    }
  }

  static async SignUpCrm(params) {
    try {
      const { data } = await axios.post(
        "https://wh.smarthook.io/-8vY0mUbY6",
        params
      );
      return data;
    } catch (e) {
      return e.response.data;
    }
  }

  static async SignIn(params) {
    try {
      const { data } = await this.API().post("/user/sign-in", params);
      return data;
    } catch (e) {
      return e.response.data.message;
    }
  }

  static async GetUserInfo() {
    try {
      const { data } = await this.API().get("/user/get-my-info");
      return data;
    } catch (e) {
      return e.response.data.message;
    }
  }

  static async UpdateUser(id, params) {
    try {
      const { data } = await this.API().patch(`/user/${id}/update`, params);
      return data;
    } catch (e) {
      return e.response.data.statusCode;
    }
  }

  static async CreateUserCrm(params) {
    try {
      const { data } = await axios.post(
        "https://wh.smarthook.io/-8vY0mUbY6",
        params
      );
      return data;
    } catch (e) {
      return e.response.data.statusCode;
    }
  }

  static async UpdatePassword(id, params) {
    try {
      const { data } = await this.API().patch(
        `/user/${id}/update-pass`,
        params
      );
      return data;
    } catch (e) {
      return e.response.data;
    }
  }

  static async ForgotPassword(email) {
    try {
      const { data } = await this.API().post(`/user/forgot-password/${email}`);
      return data;
    } catch (e) {
      return e.response.data.statusCode;
    }
  }

  static async InvestCash(params) {
    try {
      const lang = localStorage.getItem("i18nextLng") || "ru";
      const { data } = await this.API().post("/google-sheet/invest-cash", {
        ...params,
        lang,
      });
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  static async WithdrawCash(params) {
    try {
      const { data } = await this.API().post(
        "/google-sheet/withdrawal-of-funds",
        params
      );
      return data;
    } catch (e) {
      return e.response.data.statusCode;
    }
  }

  static async GetCardInfo(type) {
    try {
      const { data } = await this.API().post(
        `/google-sheet/get-invest-info/${type}`
      );
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  static async SendOTP() {
    try {
      const { data } = await this.API().post("/google-sheet/send-verify-code");
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  static async GetDashboardData() {
    try {
      const { data } = await this.API().post(
        "/google-sheet/get-dashboard-info"
      );
      return data;
    } catch (e) {
      console.log(e);
    }
  }
  static async GetDashboardInvestedData() {
    try {
      const { data } = await this.API().post(
        "/google-sheet/get-dashboard-info-invested"
      );
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  static async GetTableInvestedData(params) {
    try {
      const { data } = await this.API().post(
        "/google-sheet/get-table-invested",
        params
      );
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  static async SetDateTransaction(params) {
    try {
      const lang = localStorage.getItem("i18nextLng") || "ru";
      console.log("lang", lang);
      const { data } = await this.API().post(
        "/google-sheet/set-transaction-date",
        { ...params, lang }
      );
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  static async GetLoginData() {
    try {
      const { data } = await this.API().get("/google-sheet/get-main-dashboard");
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  static async SendCodeForSignIn(params) {
    return this.API().post("/authentication/send-code-for-sign-in", params);
  }

  static async SendCodeForSignUp(params) {
    return this.API().post("/authentication/send-code-for-sign-up", params);
  }

  static async SignInWithCode(params) {
    return this.API().post("/authentication/sign-in-with-code", params);
  }

  static async SignUpWithCode(params) {
    try {
      const { data } = await this.API().post("/authentication/sign-up-with-code", params);
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  static async GetUserSupport() {
    return this.API().get('/support/user');
  }

  static async SendSupportUser(params) {
    return this.API().post('/support/send-user', params);
  }

  static async SendSupportGuest(params) {
    return this.API().post('/support/send-guest', params);
  }
}

export default CallService;
