import { useFormik } from "formik";
import { Button, Select, MenuItem } from "@mui/material";
import { CssTextField } from "../../static/consts";
import { useState } from "react";
import SuccessModal from "./SuccessModal";
import ErrorModal from './ErrorModal'
import Modal from "./Modal";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import CallService from "../../services/CallService";

const WithdrawForm = ({ data, onClick }) => {
  console.log('modalIsId', data);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isWithdrawn, setIsWithdrawn] = useState(false);
  const [isErrorCode, setIsErrorCode] = useState(false);
  const { t } = useTranslation();

  const closeModal = () => {
    setIsOTPSent(false);
    setIsErrorCode(false)
  }

  const sendOtp = async (e) => {
    e.preventDefault();

    const data = await CallService.SendOTP();

    if (data.message === 'success') {
      setIsDisabled(false);
      setIsOTPSent(true);
    }
  };

  const validateSchema = yup.object({
    // productType: yup.string(t("select_type")).required(t("required_field")),
    // amount: yup.string(t("enter_value")).required(t("required_field")),
    wallet: yup.string(t("enter_wallet")).required(t("required_field")),
    network: yup.string(t("enter_wallet")).required(t("required_field")),
    otp: yup.number(t("enter_otp")).required(t("required_field")),
  });

  const formik = useFormik({
    initialValues: {
      productType: "Staking",
      amount: data.amount,
      // amount: "",
      wallet: "",
      network: "BEP-20",
      otp: "",
    },
    validationSchema: validateSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log('12', 123);
      setIsDisabled(true);

      const response = await CallService.WithdrawCash({ ...values, otp: Number(values.otp) });
      console.log('data', data);

      if (response?.message === 'success') {
        setIsWithdrawn(true);
        await CallService.SetDateTransaction({
          id: data.id,
          type: 'withdraw',
          wallet: values.wallet,
          network: values.network
        })
        resetForm();
      } else {
        setIsDisabled(false);
        setIsErrorCode(true)
      }
    },
  });

  return (
    <div className="bg-white font-bold text-blue w-48 sm:w-96 mx-8 my-4">
      <h2 className="p-4 text-center">{t("withdraw funds")}</h2>
      <form onSubmit={formik.handleSubmit} className="text-center">
        <Select
          name="productType"
          placeholder="Вывести из"
          // value={formik.values.productType}
          value='Staking'
          disabled={true}
          onChange={formik.handleChange}
          style={{
            width: "100%",
            marginBottom: 18,
            height: 46,
            backgroundColor: "#EDEDF4",
            color: "#191D43",
            fontWeight: "700",
            outline: "none",
          }}
          error={
            formik.touched.productType && Boolean(formik.errors.productType)
          }
        >
          <MenuItem value="Staking">Staking</MenuItem>
          <MenuItem value="Marhaba">Marhaba</MenuItem>
        </Select>
        <div className="relative">
          <CssTextField
            fullWidth
            id="amount"
            name="amount"
            disabled={true}
            placeholder={t("value")}
            style={{
              marginBottom: 12,
            }}
            inputProps={{
              style: {
                padding: 10,
                width: "100%",
                height: 20,
              },
            }}
            // value={formik.values.amount}
            value={data.amount}
            onChange={formik.handleChange}
            error={formik.touched.amount && Boolean(formik.errors.amount)}
          />
          <span className="absolute right-0 font-medium	text-sm pt-3 pr-4 text-gray">
            USDT / Token
          </span>
        </div>
        <CssTextField
          fullWidth
          id="wallet"
          name="wallet"
          placeholder={t("wallet")}
          type="text"
          style={{
            marginBottom: 12,
          }}
          inputProps={{
            style: {
              padding: 10,
            },
          }}
          value={formik.values.wallet}
          onChange={formik.handleChange}
          error={formik.touched.wallet && Boolean(formik.errors.wallet)}
        />
        <CssTextField
          fullWidth
          id="network"
          name="network"
          disabled={true}
          placeholder={t("network")}
          style={{
            marginBottom: 12,
          }}
          inputProps={{
            style: {
              padding: 10,
            },
          }}
          value={formik.values.network}
          onChange={formik.handleChange}
          error={formik.touched.network && Boolean(formik.errors.network)}
        />
        <div className="relative pb-4">
          <CssTextField
            fullWidth
            id="otp"
            name="otp"
            placeholder="OTP"
            type="text"
            inputProps={{
              style: {
                padding: 10,
                marginBottom: 2,
                width: "60%",
              },
            }}
            value={formik.values.otp}
            onChange={formik.handleChange}
            error={formik.touched.otp && Boolean(formik.errors.otp)}
            helperText={formik.touched.otp && formik.errors.otp}
          />
          <Button
            variant="contained"
            onClick={sendOtp}
            style={{
              position: "absolute",
              zIndex: 2,
              top: 0,
              right: 0,
              height: 40,
              width: "35%",
              color: "#FFF",
              backgroundColor: "#191D43",
              boxShadow: "none",
              textTransform: "none",
              fontWeight: "600",
              borderRadius: "0px 8px 8px 0px",
              fontSize: "14px"
            }}
          >
            {t("send")}
          </Button>
        </div>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          disabled={isDisabled}
          type="submit"
          style={{
            opacity: isDisabled ? '0.75' : '1',
            borderRadius: 8,
            height: 50,
            width: "100%",
            backgroundColor: "#4BCBC8",
            fontSize: "16px",
            textTransform: "none",
            marginBottom: 20,
          }}
        >
          {t("want_withdraw")}
        </Button>
      </form>
      <Modal show={isOTPSent} onClick={closeModal} onClose={closeModal}>
        <SuccessModal onClick={closeModal} text={t("success_otp_send")} />
      </Modal>
      <Modal show={isWithdrawn} onClick={onClick} onClose={onClick}>
        <SuccessModal onClick={onClick} text={t("success_invest")} />
      </Modal>
      <Modal show={isErrorCode} onClick={closeModal} onClose={closeModal}>
        <ErrorModal onClick={closeModal} text={t("error_code_modal")} />
      </Modal>
    </div>
  );
};

export default WithdrawForm;
