import { useFormik } from "formik";
import { Button } from "@mui/material";
import { CssTextField } from "../../static/consts";
import { useState } from "react";
import Modal from "../Modals/Modal";
import SuccessModal from "../Modals/SuccessModal";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import CallService from "../../services/CallService";
import { useNavigate } from "react-router-dom";

const PasswordForm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [wrongPass, setWrongPass] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const closeModal = () => {
    setIsModalOpen(false);
    navigate("/");
  };

  const isDisabled = () => setIsChecked(!isChecked);

  const validationSchema = yup.object({
    oldPassword: yup
      .string(t("enter_old_password"))
      .required(t("required_field")),
    newPassword: yup
      .string(t("enter_new_password"))
      .required(t("required_field"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z!@#$%^&*?\d]{6,}$/,
        t("correct_password")
      ),
    repeatNewPassword: yup
      .string(t("repeat_new_password"))
      .required(t("required_field"))
      .oneOf([yup.ref("newPassword"), null], t("match_password")),
  });
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      repeatNewPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { id } = localStorage;
      const pass = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      };
      const data = await CallService.UpdatePassword(id, pass);
      if (data) {
        setIsModalOpen(true);
        formik.resetForm();
      } else {
        setWrongPass(true);
      }
    },
  });

  return (
    <>
      <div className="mb-4 text-left text-blue font-bold">
        <label className="inline-flex items-center mt-3">
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 bg-green"
            checked={isChecked}
            onChange={isDisabled}
          />
          <span className="ml-2 text-blue">{t("pass_change")}</span>
        </label>
      </div>
      <div
        className={
          "bg-white rounded-xl font-bold text-blue mb-24 shadow mx-auto" +
          (isChecked ? "" : " text-gray")
        }
      >
        <h2 className="p-4 text-lg">{t("password_change")}</h2>
        <form onSubmit={formik.handleSubmit}>
          <CssTextField
            fullWidth
            id="oldPassword"
            name="oldPassword"
            type="password"
            placeholder={t("old_password")}
            disabled={!isChecked}
            style={{
              marginBottom: 12,
              width: "90%",
              fontSize: "16px",
              color: "#191D43",
            }}
            inputProps={{
              style: {
                padding: 10,
              },
            }}
            value={formik.values.oldPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
            }
            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
          />

          <p className={wrongPass ? "text-left ml-8 h-8 text-red" : "hidden"}>
            {t("wrong_pass")}
          </p>

          <CssTextField
            fullWidth
            id="newPassword"
            name="newPassword"
            type="password"
            placeholder={t("new_password")}
            disabled={!isChecked}
            style={{
              marginBottom: 12,
              width: "90%",
            }}
            inputProps={{
              style: {
                padding: 10,
              },
            }}
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.newPassword && Boolean(formik.errors.newPassword)
            }
            helperText={formik.touched.newPassword && formik.errors.newPassword}
          />
          <CssTextField
            fullWidth
            id="repeatNewPassword"
            name="repeatNewPassword"
            type="password"
            placeholder={t("repeat_password")}
            disabled={!isChecked}
            style={{
              width: "90%",
            }}
            inputProps={{
              style: {
                padding: 10,
              },
            }}
            value={formik.values.repeatNewPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.repeatNewPassword &&
              Boolean(formik.errors.repeatNewPassword)
            }
            helperText={
              formik.touched.repeatNewPassword &&
              formik.errors.repeatNewPassword
            }
          />
          <p className="w-11/12 mx-auto text-base text-gray font-medium m-4">
            {t("password_info")}
          </p>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={!isChecked}
            className="btn-gradient btn-opacity"
            style={{
              borderRadius: 8,
              height: 50,
              width: "90%",
              backgroundColor: "#4BCBC8",
              fontSize: "16px",
              textTransform: "none",
              marginBottom: 20,
            }}
          >
            {t("pass_change")}
          </Button>
        </form>
        <Modal show={isModalOpen} onClose={closeModal}>
          <SuccessModal
            onClick={closeModal}
            text={t("success_password_update")}
          />
        </Modal>
      </div>
    </>
  );
};

export default PasswordForm;
