import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";

const InvestCardInfo = ({ cardInfo, modal, name, closeModal }) => {
  const { t } = useTranslation();

  return (
    <div className={(modal || closeModal) ? "text-center mx-auto mt-8 ml-4 mr-4" : " hidden"}>
      <div className={closeModal ? "w-64 sm:w-96 text-center mx-auto mx-8 my-8 mt-8" : ""}>
        <div className="my-4">
          <p className="font-bold text-blue text-xl">{cardInfo?.deposits || 0} USD</p>
          <span className="text-gray text-sm">
            {t("you_invested_in")} {name}
          </span>
        </div>
        {name === "Marhaba" ? (
          <div className="my-4">
            <p className="font-bold text-blue text-xl">{cardInfo?.availableAmount || 0} MRHB ({cardInfo?.totalAmount || 0} MRHB)</p>
            <span className="text-gray text-sm">{t("available_total_amount")}</span>
          </div>
        ) : (
          <div className="my-4">
            <p className="font-bold text-blue text-xl">{cardInfo?.earnings || 0} USD</p>
            <span className="text-gray text-sm">{t("you_earned")}</span>
          </div>
        )}
        <div className="my-4">
          <p className="font-bold text-blue text-xl">{cardInfo?.currentValue || 0} USD</p>
          <span className="text-gray text-sm">{t("available_value")}</span>
        </div>
      </div>

      {closeModal && (
        <Button
          color="primary"
          variant="contained"
          type="submit"
          onClick={closeModal}
          style={{
            borderRadius: 8,
            height: 50,
            width: "100%",
            maxWidth: 245,
            color: '#191D43',
            backgroundColor: "rgb(237, 237, 244)",
            boxShadow: 'none',
            fontSize: "16px",
            fontWeight: 'bold',
            textTransform: "none",
            marginBottom: 20,
          }}
        >
          {t("okay2")}
        </Button>
      )}
    </div>
  );
};

export default InvestCardInfo;
