import React from "react";
import { useTranslation } from "react-i18next";

const Instruction = () => {
  const { t } = useTranslation();

  return (
    <>
      <p className="text-xs  sm:text-lg italic">
        {t("modal_instuction_wallet_cursiv")}
      </p>

      <span className="text-xs sm:text-lg font-bold mt-3 block">
        {t("modal_instuction_wallet_title")}
      </span>
      <p className="text-xs sm:text-lg">
        {t("modal_instuction_wallet_content_1")}
      </p>

      <div className="mt-2 text-xs sm:text-lg">
        <span>{t("modal_instuction_wallet_list_title")}</span>

        <div className="flex items-start mt-2">
          <span>1. </span>
          <p className="pl-3">{t("modal_instuction_wallet_list_item_1")}</p>
        </div>
        <div className="flex items-start mt-2">
          <span>2. </span>
          <p className="pl-3">{t("modal_instuction_wallet_list_item_2")}</p>
        </div>
        <div className="flex items-start mt-2">
          <span>3. </span>
          <p className="pl-3">{t("modal_instuction_wallet_list_item_3")}</p>
        </div>
      </div>

      <p className="text-xs sm:text-lg mt-2">
        {t("modal_instuction_wallet_content_2")}
      </p>
      <p className="text-xs sm:text-lg mt-2">
        {t("modal_instuction_wallet_content_3")}
      </p>
      <p className="text-xs sm:text-lg mt-2">
        {t("modal_instuction_wallet_content_4")}
      </p>

      <p className="text-xs sm:text-lg mt-2">
        {t("modal_instuction_wallet_content_5")}
      </p>
      <div className="text-xs sm:text-lg mt-2 flex flex-col">
        <div className="pl-3 flex flex-col">
          <span>{t("modal_instuction_wallet_list_item_4")}</span>
          <span>{t("modal_instuction_wallet_list_item_5")}</span>
          <span>{t("modal_instuction_wallet_list_item_6")}</span>
          <span>{t("modal_instuction_wallet_list_item_7")}</span>
          <span>{t("modal_instuction_wallet_list_item_8")}</span>
          <span>{t("modal_instuction_wallet_list_item_9")}</span>
          <span>{t("modal_instuction_wallet_list_item_10")} </span>
          <span>{t("modal_instuction_wallet_list_item_11")}</span>
        </div>
      </div>

      <div className="text-xs sm:text-lg mt-2 ">
        <p className="mb-2">{t("modal_instuction_wallet_content_6")}</p>

        <div className="pl-3 flex flex-col">
          <span>{t("modal_instuction_wallet_list_item_12")} </span>
          <span>{t("modal_instuction_wallet_list_item_13")}</span>
          <span>{t("modal_instuction_wallet_list_item_14")}</span>
          <span>{t("modal_instuction_wallet_list_item_15")}</span>
        </div>
      </div>

      <div className="text-xs sm:text-lg">
        <p className="mt-3 font-bold">
          {t("modal_instuction_unstaking_title")}
        </p>

        <p className="mt-2">{t("modal_instuction_unstaking_content")}</p>

        <p className="text-red italic">
          <span className=" font-bold mr-2">
            {t("modal_instuction_unstaking_content_1")}
          </span>
          {t("modal_instuction_unstaking_content_2")}
        </p>

        <p className=" mt-3 font-bold">
          {t("modal_instuction_unstaking_content_3")}
        </p>

        <p className="mt-3">
          {t("modal_instuction_unstaking_content_4")}
          <span className=" font-bold">
            {t("modal_instuction_unstaking_content_5")}
          </span>
        </p>
        <p className="mt-3">{t("modal_instuction_unstaking_content_6")}</p>

        <p className="mt-3">{t("modal_instuction_unstaking_content_7")}</p>
        <p className="mt-3">{t("modal_instuction_unstaking_content_8")}</p>
      </div>
    </>
  );
};

export default Instruction;
