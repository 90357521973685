import React from "react";
import { Navigate } from "react-router-dom";
import {
  LandingHeader,
  LandingMain,
  // LandingIntegration,
  LandingCustomer,
  LandingValute,
  LandingWorking,
  LandingMarket,
  LandingProject,
  LandingPeer,
  LandingPersonal,
  Accordion,
  LandingFooter,
} from "./";

const Landing = () => {
  const { token } = localStorage;

  return (
    <>
      {!token ? (
        <div className="">
          <div className="max-w-7xl mx-auto lg:px-0 px-5">
            <LandingHeader />
            <div style={{
              marginTop: '4rem'
            }}>
              <LandingMain />
            </div>
            {/* <LandingIntegration /> */}
            <LandingCustomer />
            <LandingValute />
            <LandingWorking />
            <LandingMarket />
            <LandingProject />
            <LandingPeer />
          </div>
          <LandingPersonal />
          <div className="max-w-7xl mx-auto lg:px-0 px-5">
            <Accordion />
          </div>
          <LandingFooter />
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default Landing;
