import React from 'react'

const PositionClient = () => {
    return (
        <div>
            <div>
                <h1 className=" text-sm sm:text-xl font-bold text-center">
                    1. ОБЩИЕ ПОЛОЖЕНИЯ
                </h1>
                <div className="">
                    <div>
                        <p className=" text-xs sm:text-lg mt-2">
                            <span className=" mr-1 sm:mr-2">1.1</span>
                            Настоящее <span className='font-bold'>Положение об обработке и защите персональных данных пользователей сайта Ethicalnode.com</span> (далее – Положение) разработано в соответствии со следующими нормативными правовыми актами:
                        </p>

                        <div className=' pl-3 sm:pl-5'>
                            <p className=" text-xs sm:text-lg mt-1 ">
                                - Конституция РФ от 12 декабря 1993г.;
                            </p>
                            <p className=" text-xs sm:text-lg mt-1 ">
                                - Федеральный закон от 27 июля 2006г. № 152-ФЗ «О персональных данных»;
                            </p>
                            <p className=" text-xs sm:text-lg mt-1 ">
                                - Постановление Правительства РФ от 15 сентября 2008г. № 687 «Об утверждении Положения об особенностях обработки персональных данных, осуществляемой без использования средств автоматизации»;
                            </p>
                            <p className=" text-xs sm:text-lg mt-1 ">
                                - Постановление Правительства РФ от 1 ноября 2012г. № 1119 «Об утверждении требований к защите персональных данных при их обработке в информационных системах персональных данных»;
                            </p>
                            <p className=" text-xs sm:text-lg mt-1 ">
                                - Указ Президента РФ от 6 марта 1997г. № 188 «Об утверждении Перечня сведений конфиденциального характера»;
                            </p>
                            <p className=" text-xs sm:text-lg mt-1 ">
                                - Приказ Роскомнадзора от 5 сентября 2013г. № 996 «Об утверждении требований и методов по обезличиванию персональных данных».
                            </p>
                        </div>

                    </div>
                    <div>
                        <p className=" text-xs sm:text-lg mt-2">
                            <span className=" mr-1 sm:mr-2">1.2</span>
                            Цель разработки настоящего Положения – определение порядка обработки персональных данных <span className=' font-bold'>пользователей сайта Ethicalnode.com</span>, нормативное закрепление системы организационно-правовых и технических средств защиты персональных данных <span className=' font-bold'>пользователей сайта Ethicalnode.com</span> от несанкционированного доступа, разглашения или утраты, а также установление ответственности должностных лиц, имеющих доступ к персональным данным, за невыполнение требований норм, регулирующих порядок обработки и защиты персональных данных.
                        </p>
                    </div>
                    <div>
                        <p className=" text-xs sm:text-lg mt-2">
                            <span className=" mr-1 sm:mr-2">1.3</span>
                            Настоящее Положение устанавливает порядок получения, учета, обработки, накопления и хранения документов, содержащих сведения, отнесенные к персональным данным <span className=' font-bold'>пользователей сайта Ethicalnode.com</span>. Под <span className=' font-bold'>пользователями сайта Ethicalnode.com</span> подразумеваются лица, посетившие сайт Ethicalnode.com и присоединившиеся к пользовательскому соглашению Ethicalnode.com.
                        </p>
                    </div>
                    <div>
                        <p className=" text-xs sm:text-lg mt-2">
                            <span className=" mr-1 sm:mr-2">1.4</span>
                            Настоящее Положение и изменения к нему утверждаются и вводятся руководителем Ethicalnode.com.
                        </p>
                    </div>
                </div>
            </div>

            <div className='mt-2 sm:mt-5'>
                <h1 className=" text-sm sm:text-xl font-bold text-center mb-2">
                    2. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ
                </h1>
                <div className="mt-2">
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">2.1</span>
                        В настоящем Положении использованы следующие термины с соответствующими определениями:
                    </p>
                    <div className='pl-2 sm:pl-5'>
                        <p className=" text-xs sm:text-lg mt-2">
                            <span className=' font-bold'>Персональные данные</span> - информация, необходимая Ethicalnode.com для оказания услуг пользователям сайта Ethicalnode.com. Под информацией о пользователей сайта Ethicalnode.com понимаются сведения о дате и времени посещения сайта, устройстве входа на сайт, браузере посетителя сайта, данные, введенные пользователем в Личном кабинете и указанные при авторизации.
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            <span className=' font-bold'>Конфиденциальность персональных данных</span> - обязательное для соблюдения назначенного ответственного лица, получившего доступ к персональным данным, требование не допускать их распространения без согласия гражданина или иного законного основания.
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            <span className=' font-bold'>Обработка персональных данных</span> - Обработка персональных данных - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача, (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных пользователей сайта Ethicalnode.com.
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            <span className=' font-bold'>Использование персональных данных</span> - Действия (операции) с персональными данными, совершаемые должностным лицом в целях принятия решений или совершения иных действий, порождающих юридические последствия в отношении граждан либо иным образом, затрагивающих их права и свободы или права и свободы других лиц.
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            <span className=' font-bold'>Распространение персональных данных</span> - Действия, направленные на передачу персональных данных определенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом.
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            <span className=' font-bold'>Обезличивание персональных данных</span> - Действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных.
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            <span className=' font-bold'>Уничтожение персональных данных</span> - Действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и/или в результате которых уничтожаются материальные носители персональных данных.
                        </p>
                    </div>
                </div>

                <div className="mt-2">
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">2.2</span>
                        Состав персональных данных <span className='font-bold'>пользователей сайта Ethicalnode.com:</span>
                    </p>
                    <div className='pl-2 sm:pl-5'>
                        <p className=" text-xs sm:text-lg mt-2">
                            - Номер телефона;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - E-mail;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - Фамилия Имя
                        </p>
                    </div>
                </div>
            </div>

            <div className='mt-2 sm:mt-5'>
                <h1 className=" text-sm sm:text-xl font-bold text-center mb-2">
                    3. ТРЕБОВАНИЯ К Ethicalnode.com
                </h1>
                <div className="mt-2">
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">3.1</span>
                        В целях обеспечения прав пользователей сайта Ethicalnode.com и его представители при обработке персональных данных пользователей сайта Ethicalnode.com обязаны соблюдать следующие общие требования:
                    </p>
                    <div className='pl-2 sm:pl-5'>
                        <p className=" text-xs sm:text-lg mt-2">
                            - обработка персональных данных осуществляется исключительно в целях оказания услуг пользователям сайта Ethicalnode.com;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - все персональные данные пользователей сайта Ethicalnode.com следует получать, оформив согласие на обработку персональных данных на сайте Ethicalnode.com.
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - Ethicalnode.com вправе передавать данные пользователей сайта Ethicalnode.com, в случае если это необходимо для оказания услуг пользователям сайта Ethicalnode.com.
                        </p>
                    </div>
                </div>
                <div className="mt-2">
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">3.2</span>
                        Работники Ethicalnode.com и иные лица, имеющие доступ к персональным данным пользователей сайта Ethicalnode.com в связи с исполнением трудовых и гражданско-правовых обязанностей обязаны:
                    </p>
                    <div className='pl-2 sm:pl-5'>
                        <p className=" text-xs sm:text-lg mt-2">
                            - знать законодательство РФ, работники также обязаны знать локальные нормативные документы Ethicalnode.com о защите персональных данных;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - докладывать руководству Ethicalnode.com обо всех фактах и попытках несанкционированного доступа, разглашения и утечки персональных данных и других нарушениях;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - обеспечивать хранение информации, содержащей персональные данные пользователей сайта Ethicalnode.com, исключающее доступ к ним третьих лиц;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - в период нахождения в отпуске, служебной командировке и иных случаях длительного отсутствия на рабочем месте, передавать документы, носители и иные необходимые сведения (ключи, пароли), содержащие персональные данные пользователей сайта Ethicalnode.com, лицам, допущенным к обработке персональных данных, в части их касающейся;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - в случае, если такое лицо не назначено, то документы и иные носители, содержащие персональные данные пользователей сайта Ethicalnode.com, передавать другому работнику, имеющему доступ к персональным данным пользователей сайта Ethicalnode.com, по указанию руководителя своего структурного подразделения.
                        </p>
                    </div>
                </div>


            </div>

            <div className='mt-2 sm:mt-5'>
                <h1 className=" text-sm sm:text-xl font-bold text-center mb-2">
                    4. ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЕЙ САЙТА Ethicalnode.com, ПРЕДОСТАВЛЯЮЩИХ СВОИ ПЕРСОНАЛЬНЫЕ ДАННЫЕ
                </h1>
                <div className="mt-2">
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">4.1</span>
                        Закрепление прав пользователей сайта Ethicalnode.com, регламентирующих защиту их персональных данных, обеспечивает сохранность полной и точной информации о нем.
                    </p>
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">4.2</span>
                        Пользователи сайта Ethicalnode.com, регистрируясь на сайте Ethicalnode.com подтверждают свое согласие на обработку и хранение персональных данных, а также передачу данных третьим лицам.
                    </p>
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">4.3</span>
                        В целях обеспечения защиты персональных данных, хранящихся у Ethicalnode.com, пользователи сайта Ethicalnode.com имеют право на:
                    </p>
                    <div className='pl-2 sm:pl-5'>
                        <p className=" text-xs sm:text-lg mt-2">
                            - полную информацию о своих персональных данных и обработке этих данных;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - свободный бесплатный доступ к своим персональным данным, за исключением случаев, предусмотренных законодательством РФ;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - требование об исключении или исправлении неверных, или неполных персональных данных, а также данных, обработанных с нарушением законодательства РФ.
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - обжалование в суд любых неправомерных действий или бездействий при обработке и защите своих персональных данных.
                        </p>

                    </div>
                </div>



            </div>

            <div className='mt-2 sm:mt-5'>
                <h1 className=" text-sm sm:text-xl font-bold text-center mb-2">
                    5. СБОР, ОБРАБОТКА И ХРАНЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЕЙ
                </h1>
                <div className="mt-2">
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">5.1</span>
                        Сбор, обработка, хранение и любое другое использование персональных данных осуществляется исключительно в целях оказания услуг пользователям сайта Ethicalnode.com.
                    </p>
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">5.2</span>
                        В Ethicalnode.com применяются следующие способы получения персональных данных:
                    </p>
                    <div className='pl-2 sm:pl-5'>
                        <p className=" text-xs sm:text-lg mt-2">
                            - заполнение пользователем сайта Ethicalnode.com соответствующих форм на сайте Ethicalnode.com;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - заполнение пользователем сайта Ethicalnode.com соответствующих форм на сайте Ethicalnode.com;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - сбор данных из общедоступных источников;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - получение информации автоматизированным способом через средства обмена данными устройства пользователя сайта Ethicalnode.com.
                        </p>
                    </div>
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">5.3</span>
                        Все меры конфиденциальности при сборе, обработке и хранении персональных данных пользователей сайта Ethicalnode.com распространяются как на бумажные, так и на электронные носители информации.
                    </p>
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">5.4</span>
                        Ethicalnode.com не имеет права получать и обрабатывать персональные данные пользователей сайта Ethicalnode.com о его расовой, национальной принадлежности, политических взглядах, религиозных или философских убеждениях, состоянии здоровья, интимной жизни и иных убеждениях и частной жизни.
                    </p>
                    <p className=" text-xs sm:text-lg mt-2">
                        Обработка персональных данных пользователей сайта Ethicalnode.com возможна без его согласия в следующих случаях:
                    </p>

                    <div className='pl-2 sm:pl-5'>
                        <p className=" text-xs sm:text-lg mt-2">
                            - персональные данные являются общедоступными;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - по требованию полномочных государственных органов в случаях, предусмотренных федеральным законом.
                        </p>
                    </div>

                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">5.5</span>
                        Согласие пользователей сайта Ethicalnode.com на обработку персональных данных не требуется в следующих случаях:
                    </p>

                    <div className='pl-2 sm:pl-5'>
                        <p className=" text-xs sm:text-lg mt-2">
                            - обработка персональных данных осуществляется для статистических или иных научных целей при условии обязательного обезличивания персональных данных;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - обработка персональных данных необходима для защиты жизни, здоровья или иных жизненно важных интересов пользователей сайта Ethicalnode.com, если получение его согласия невозможно.
                        </p>
                    </div>

                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">5.6</span>
                        При обработке персональных данных в информационной системе сайта Ethicalnode.com обеспечивается:
                    </p>

                    <div className='pl-2 sm:pl-5'>
                        <p className=" text-xs sm:text-lg mt-2">
                            - предотвращение несанкционированного доступа к персональным данным и (или) передачи их лицам, не имеющим права доступа к такой информации;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - своевременное обнаружение фактов несанкционированного доступа к персональным данным;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - недопущение воздействия на технические средства автоматизированной обработки персональных данных, в результате которого может быть нарушено их функционирование;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - возможность незамедлительного восстановления персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - постоянный контроль за обеспечением уровня защищенности персональных данных.
                        </p>
                    </div>

                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">5.7</span>
                        Персональные данные Ethicalnode.com обрабатываются и хранятся на серверах, доступ, к которым ограничивается в соответствии с настоящим Положением.

                    </p>

                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">5.8</span>
                        Хранение персональных данных происходит в порядке, исключающем их утрату или неправомерное использование.
                    </p>

                </div>



            </div>

            <div className='mt-2 sm:mt-5'>
                <h1 className=" text-sm sm:text-xl font-bold text-center mb-2">
                    6. ПЕРЕДАЧА ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЕЙ САЙТА Ethicalnode.com
                </h1>
                <div className="mt-2">
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">6.1</span>
                        Передача персональных данных внутри Ethicalnode.com.
                    </p>
                    <div className='pl-2 sm:pl-5'>
                        <p className=" text-xs sm:text-lg mt-2">
                            <span className=''>6.1.1</span> Передача персональных данных между подразделениями Ethicalnode.com осуществляется только между работниками и лицами, имеющими доступ к персональным данным пользователей сайта Ethicalnode.com.
                        </p>
                        <div>
                            <p className=" text-xs sm:text-lg mt-2">
                                <span className=''>6.1.2</span> Ethicalnode.com вправе передавать персональные данные пользователей сайта Ethicalnode.com в случае необходимости оказания услуг.
                            </p>
                            <p className=" text-xs sm:text-lg mt-1">
                                В Ethicalnode.com приняты следующие способы передачи персональных данных:
                            </p>
                            <p className=" text-xs sm:text-lg mt-2">
                                - передача информации на электронных и бумажных носителях;
                            </p>
                            <p className=" text-xs sm:text-lg mt-2">
                                - передача на электронных носителях посредством корпоративной информационной системы;
                            </p>
                            <p className=" text-xs sm:text-lg mt-2">
                                - передача по каналам электронной связи.
                            </p>
                        </div>

                        <div>
                            <p className=" text-xs sm:text-lg mt-2">
                                <span className=''>6.1.3</span> При передаче персональных данных пользователей сайта Ethicalnode.com должны соблюдаться следующие требования:
                            </p>
                            <p className=" text-xs sm:text-lg mt-1">
                                - осуществлять передачу персональных данных пользователей сайта Ethicalnode.com в пределах Ethicalnode.com;
                            </p>
                            <p className=" text-xs sm:text-lg mt-2">
                                - разрешать доступ к персональным данным пользователей сайта Ethicalnode.com только работникам, допущенным к работе с персональными данными и заполненным обязательством о неразглашении персональных данных работников Ethicalnode.com.
                            </p>
                            <p className=" text-xs sm:text-lg mt-2">
                                - передавать персональные данные в порядке, установленном Федеральным законом № 152-ФЗ «О персональных данных» и иными федеральными законами.
                            </p>
                        </div>

                        <p className=" text-xs sm:text-lg mt-2">
                            <span className=''>6.1.4</span> При передаче персональных данных пользователей сайта Ethicalnode.com, Ethicalnode.com предупреждает лиц, получающих данную информацию, о том, что эти данные используются в целях, для которых они сообщены. Лица, получающие персональные данные пользователей сайта Ethicalnode.com, обязаны соблюдать конфиденциальность и требования Федерального закона № 152-ФЗ «О персональных данных».
                        </p>
                    </div>
                </div>

                <div className="mt-2">
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">6.2</span>
                        Согласия пользователей сайта Ethicalnode.com на передачу их персональных данных третьим лицам не требуется в случаях:
                    </p>
                    <div className='pl-2 sm:pl-5'>
                        <p className=" text-xs sm:text-lg mt-2">
                            - для оказания услуг пользователям сайта Ethicalnode.com;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - когда третьи лица оказывают услуги Ethicalnode.com на основании заключенных договоров;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - установленных Федеральным законом № 152-ФЗ «О персональных данных» и настоящим Положением.
                        </p>
                    </div>
                </div>

                <div className="mt-2">
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">6.3</span>
                        Не допускается передача персональных данных пользователей сайта Ethicalnode.com в коммерческих целях, за исключением оказания услуг и продажи товаров пользователям сайта Ethicalnode.com. Также не допускается передача персональных данных третьим лицам по запросам, переданным посредством телефонной или электронной связи.
                    </p>
                </div>
                <div className="mt-2">
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">6.4</span>
                        Передача третьим лицам документов, содержащих персональные данные пользователей сайта Ethicalnode.com, осуществляется при наличии у лица, уполномоченного на их получение соглашения о конфиденциальности, либо наличие в соответствующей форме договора с третьим лицом пунктов о неразглашении конфиденциальной информации, в том числе, предусматривающих защиту персональных данных пользователей сайта Ethicalnode.com, а также письменного запроса на предоставление информации, содержащей персональные данные пользователей сайта Ethicalnode.com.
                    </p>
                </div>
                <div className="mt-2">
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">6.5</span>
                        Ответственность за предоставление персональных данных пользователей сайта Ethicalnode.com несут работник, который осуществляет передачу персональных данных третьим лицам и руководитель структурного подразделения, дающий соответствующее указание.
                    </p>
                </div>
                <div className="mt-2">
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">6.6</span>
                        Предоставление персональных данных пользователей сайта Ethicalnode.com государственным органам производится в соответствии с требованиями действующего законодательства и настоящего Положения.
                    </p>
                </div>
            </div>

            <div className='mt-2 sm:mt-5'>
                <h1 className=" text-sm sm:text-xl font-bold text-center mb-2">
                    7. ДОСТУП К ПЕРСОНАЛЬНЫМ ДАННЫМ ПОЛЬЗОВАТЕЛЕЙ САЙТА Ethicalnode.com
                </h1>
                <div className="mt-2">
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">7.1</span>
                        Защита персональных данных в Ethicalnode.com предусматривает ограничение к ним доступа.
                    </p>
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">7.2</span>
                        Пользователь сайта Ethicalnode.com имеет право на получение следующей информации, касающейся обработки его персональных данных:
                    </p>
                    <div className='pl-2 sm:pl-5'>
                        <p className=" text-xs sm:text-lg mt-2">
                            - подтверждение факта обработки персональных данных Ethicalnode.com;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - правовые основания и цели обработки персональных данных;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - правовые основания и цели обработки персональных данных;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - наименование и место нахождения Ethicalnode.com, сведения о работниках, которые имеют доступ к персональным данным или которым могут быть раскрыты персональные данные на основании договора с Ethicalnode.com или на основании действующего законодательства РФ;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - сроки обработки персональных данных, в том числе сроки их хранения;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку персональных данных по поручению Ethicalnode.com, если обработка поручена или будет поручена такому лицу;
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            - иные сведения, предусмотренные Федеральными законами.
                        </p>
                    </div>
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">7.3</span>
                        Правом доступа внутри Ethicalnode.com к персональным данным обладают только уполномоченные работники. Полномочия уполномоченных работников подтверждается также Приказом руководителя Ethicalnode.com.
                    </p>
                    <p className=" text-xs sm:text-lg mt-2">
                        При этом указанные работники имеют право получать только те персональные данные, которые необходимы для выполнения конкретных функций, и в целях, для которых они сообщены.
                    </p>
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">7.4</span>
                        Доступ к персональным данным работника третьих лиц.
                    </p>
                    <div className='pl-2 sm:pl-5'>
                        <p className=" text-xs sm:text-lg mt-2">
                            <span className=''>7.4.1</span> Доступ представителей государственных органов к персональным данным регламентируется законодательством РФ.
                        </p>
                        <p className=" text-xs sm:text-lg mt-2">
                            <span className=''>7.4.2</span> К числу имеющих право доступа к персональным данным вне Ethicalnode.com относятся государственные и негосударственные функциональные структуры.
                        </p>

                        <p className=" text-xs sm:text-lg mt-2">
                            <span className=''>7.4.3</span> Надзорно-контрольные органы имеют доступ к информации только в сфере своей компетенции, при этом составляется расписка об ознакомлении с коммерческой тайной и/или персональными данными.
                        </p>
                    </div>
                </div>




            </div>

            <div className='mt-2 sm:mt-5'>
                <h1 className=" text-sm sm:text-xl font-bold text-center mb-2">
                    8. ОРГАНИЗАЦИЯ ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ
                </h1>
                <div className="mt-2">
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">8.1</span>
                        Защита персональных данных пользователей сайта Ethicalnode.com от неправомерного их использования или утраты обеспечивается Ethicalnode.com за счет собственных средств в порядке, установленном законодательством РФ.
                    </p>
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">8.2</span>
                        При обработке персональных данных принимаются необходимые организационные и технические меры по обеспечению их конфиденциальности, целостности и доступности.
                    </p>
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">8.3</span>
                        В рамках реализации пунктов настоящего Положения, руководитель Ethicalnode.com издает приказ о назначении лиц, ответственных за соблюдение порядка работы с персональными данными пользователей сайта Ethicalnode.com, на которых возлагаются все обязанности по обеспечению конфиденциальности полученных данных, а также организации работы с ними.
                    </p>
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">8.4</span>
                        Меры по защите при обработке персональных данных в информационных системах персональных данных с использованием средств автоматизации осуществляется в соответствии с требованиями постановления Правительства РФ 1119 от 01.11.2012 «Об утверждении требований к защите персональных данных при их обработке в информационных системах персональных данных».
                    </p>
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">8.5</span>
                        Общий контроль за соблюдением работниками Ethicalnode.com мер по защите персональных данных работника осуществляет руководитель Ethicalnode.com.
                    </p>
                </div>
            </div>

            <div className='mt-2 sm:mt-5'>
                <h1 className=" text-sm sm:text-xl font-bold text-center mb-2">
                    9. ОТВЕТСТВЕННОСТЬ ЗА РАЗГЛАШЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЕЙ САЙТА Ethicalnode.com
                </h1>
                <div className="mt-2">
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">9.1</span>
                        Персональная ответственность – одно из главных требований функционирования системы защиты персональной информации и обязательное условие обеспечения эффективности этой системы.
                    </p>
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">9.2</span>
                        Лицо, разрешающее доступ к конфиденциальному документу, несет персональную ответственность за данное разрешение.
                    </p>
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">9.3</span>
                        Каждый работник Ethicalnode.com, получающий для работы документ (информационный носитель, иной доступ к персональным данным), содержащий персональные данные пользователей сайта Ethicalnode.com, несет личную ответственность за сохранность носителя информации.
                    </p>
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">9.4</span>
                        Лица, виновные в нарушении норм, регулирующих получение, обработку и защиту персональных данных пользователей сайта Ethicalnode.com, несут материальную, дисциплинарную, административную, гражданско-правовую или уголовную ответственность в порядке, установленном федеральными законами.
                    </p>
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">9.5</span>
                        Работники, имеющие доступ к персональным данным пользователей сайта Ethicalnode.com и совершившие указанный проступок, несут полную материальную ответственность в случае причинения его действиями ущерба Ethicalnode.com.
                    </p>
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">9.6</span>
                        Работники, имеющие доступ к персональным данным пользователей сайта Ethicalnode.com, виновные в незаконном разглашении или использовании персональных данных пользователей сайта Ethicalnode.com без согласия пользователей сайта Ethicalnode.com из корыстной или иной личной заинтересованности и причинившие крупный ущерб, несут уголовную ответственности.
                    </p>
                </div>
            </div>

            <div className='mt-2 sm:mt-5'>
                <h1 className=" text-sm sm:text-xl font-bold text-center mb-2">
                    10. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ
                </h1>
                <div className="mt-2">
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">10.1</span>
                        Настоящее Положение вступает в силу с момента его утверждения руководителем Ethicalnode.com и действует бессрочно, до замены его новым Положением.
                    </p>
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">10.2</span>
                        Все изменения в Положение вносятся приказом руководителя Ethicalnode.com.
                    </p>
                    <p className=" text-xs sm:text-lg mt-2">
                        <span className=" mr-1 sm:mr-2">10.3</span>
                        Все работники Ethicalnode.com должны быть ознакомлены с настоящим Положением под роспись.
                    </p>
                </div>
            </div>

        </div>
    )
}

export default PositionClient
