import { useFormik } from "formik";
import { useState } from "react";
import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import CallService from "../../services/CallService";
import PhoneInput from "react-phone-input-2";

const SignUpForm = ({ setDataCallback }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const emailPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const phonePattern = /^(?:[0-9]{0,3})(?:(?:(?=\d{2,3}))?(\d{2,3})(?:(?!\d{3}))?)(?:(?:(?=\d{3}))?(\d{3})(?:(?!\d{3}))?)(?:(?:(?=\d{4}))?(\d{6}))$/;

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email(t("valid_email"))
      .matches(emailPattern, "Email is not valid")
      .required(t("email_required")),
    phone: yup
      .string(t("enter_phone"))
      .matches(phonePattern, t("valid_phone"))
      .required(t("phone_required")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      phone: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {


      try {
      const data = await CallService.SignUp({
        email: values.email.toLowerCase(),
        phone: "+" + values.phone
    });

          if (data.user) {
            localStorage.setItem("id", data.user.id);
            localStorage.setItem("email", data.user.email.toLowerCase());
            localStorage.setItem("token", data.accessToken);
            navigate("/profile");
        } else if (data.statusCode === 400) {
            setErrorMessage(data.message);
        }else{
            setErrorMessage(t('user_incorrect_data'))
        }
      }catch (error){
        setErrorMessage(t('user_incorrect_data'))
}



        // CallService
        //     .SendCodeForSignUp({
        //         phone: "+" + values.phone,
        //         email: values.email
        //     })
        //     .then(() => setDataCallback(values))
        //     .catch(errors => setErrorMessage(t(errors.response.data.message)));
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="h-20 border-0">
          <TextField
            id="email"
            name="email"
            placeholder={t("enter_email")}
            variant="standard"
            className="w-full lg:w-11/12 2xl:w-10/12"
            style={{
              outline: "none",
              color: "#191D43",
            }}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </div>

        <div className="h-20 border-0">
          <PhoneInput
            inputProps={{
              id: "phone",
              name: "phone",
              required: false,
              style: {
                borderRadius: 0,
                border: 'none',
                borderBottom: '1px solid #949494'
              }
            }}
            buttonStyle={{
              borderRadius: 0,
              border: 'none',
              borderBottom: '1px solid #949494',
              backgroundColor: 'white'
            }}
            enableSearch
            enableTerritories
            placeholder={t("enter_phone")}
            value={formik.values.phone}
            onChange={(value) => formik.setFieldValue('phone', value)}
            style={{
                margin: 0,
            }}
          />

          <p
            className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-1d1r5q-MuiFormHelperText-root"
            id="phone-helper-text"
            style={{
                display: formik.errors.phone ? 'inline-block' : 'none',
                color: 'red',
                fontSize: '.75rem'
            }}
          >
            {formik.errors.phone}
          </p>
        </div>

        <p className={errorMessage ? "text-red pb-2" : "hidden"}>
            {errorMessage}
        </p>

        <Button
          variant="contained"
          type="submit"
          className="w-full lg:w-6/12"
          style={{
            borderRadius: 6,
            height: 74,
            backgroundColor: "#191D43",
            fontSize: "16px",
            textTransform: "none",
            color: "#FFF",
            fontWeight: "600",
            display: "inline-block",
          }}
        >
          {t("continue")}
        </Button>

        <span className="hidden lg:inline-block mx-4 text-gray">{t("or")}</span>
        <p className="h-8 lg:hidden"></p>

        <Button
          variant="contained"
          className="w-full lg:w-3/12"
          onClick={() => navigate("/login")}
          style={{
            borderRadius: 6,
            height: 74,
            backgroundColor: "#EDEDF4",
            fontSize: "16px",
            textTransform: "none",
            color: "#191D43",
            fontWeight: "600",
            display: "inline-block",
          }}
        >
          {t("sign_in")}
        </Button>
      </form>
    </>
  );
};

export default SignUpForm;
