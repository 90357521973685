import {useFormik} from "formik";
import {Button, TextareaAutosize} from "@mui/material";
import {CssTextField} from "../../static/consts";
import SuccessModal from "../Modals/SuccessModal";
import React, {useState, useEffect} from "react";
import Modal from "../Modals/Modal";
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CallService from "../../services/CallService";
import {useNavigate} from "react-router-dom";

const SupportUserForm = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
        formik.setFieldValue('text', '');
        formik.setFieldTouched('text', false)
    };

    const {t} = useTranslation();

    const validationSchema = yup.object({
        text: yup
            .string(t("description"))
            .required(t('required_field')),
    });

    const formik = useFormik({
        initialValues: {
            text: "",
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await CallService
                .SendSupportUser(values)
                .then(() => setIsModalOpen(true))
        },
    });

    return (
        <div
            className="bg-white rounded-xl font-bold text-blue shadow mx-auto"
            style={{
                padding: '2rem',
                paddingTop: 'none',
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <TextareaAutosize
                    id="support_text"
                    name="text"
                    placeholder={t("description")}
                    type="text"
                    style={{
                        borderRadius: '10px',
                        border: '2px solid #ededf4',
                        width: '100%',
                        padding: '10px',
                        boxSizing: 'border-box'
                    }}
                    value={formik.values.text}
                    onChange={formik.handleChange}
                    minRows={5}
                />

                <p className={formik.errors.text ? "pb-4 text-red" : "hidden"}>
                    {t(formik.errors.text)}
                </p>

                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className="btn-gradient btn-opacity"
                    // disabled={!isSubmitAllowed}
                    style={{
                        borderRadius: 8,
                        height: 50,
                        width: "100%",
                        backgroundColor: "#4BCBC8",
                        fontSize: "16px",
                        textTransform: "none",
                    }}
                >
                    {t("send")}
                </Button>
            </form>
            <Modal show={isModalOpen} onClose={closeModal}>
                <SuccessModal onClick={closeModal} text={t("success_send_support")}/>
            </Modal>
        </div>
    );
};

export default SupportUserForm;
