import React, {useEffect, useState} from "react";
import "react-phone-input-2/lib/style.css";
import CallService from "../../services/CallService";

const MessageSupport = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [chat, setChat] = useState(null);

    const loadSupports = () => {
        CallService
            .GetUserSupport()
            .then(items => setChat(items.data))
            .then(() => setIsLoaded(true))
    }

    useEffect(loadSupports, [])

    return (
        <>
            { isLoaded && chat !== null ? <div
                className="bg-white rounded-xl font-bold text-blue mb-12 md:mb-20 xl:mb-32 shadow mx-auto support_container"
                style={{
                    padding: '2rem',
                    paddingTop: 'none',
                    marginTop: '3rem'
                }}
            >
                {(chat.messages || []).map((item, index) => <div className="support" key={index}>
                    {item.isUser
                        ? <div className="support__text"><span style={{fontWeight: 'bold'}}>Вы: </span>{item.text}</div>
                        : <div className="support__text"><span style={{fontWeight: 'bold'}}>Администратор: </span>{item.text}</div>
                    }
                </div>)}
            </div> : '' }
        </>
    );
};

export default MessageSupport;
