import { useState } from "react";
import i18next from "i18next";
import { Popover, List, ListItem } from "@mui/material";

const languageMap = {
  en: "EN",
  ru: "RU",
};

const LanguageSelect = ({ anchorPosLeft, anchorPosTop }) => {
  const [language, setLanguage] = useState(null);
  const selected = localStorage.getItem("i18nextLng") || "ru";

  const anchorLeft = anchorPosLeft || 0;
  const anchorTop = anchorPosTop || 0;

  return (
    <>
      <button
        onClick={({ target }) => setLanguage(target)}
        className="bg-selectGray w-20 text-blue rounded text-center outline-none cursor-pointer h-12 font-bold"
      >
        {languageMap[selected]}
        <span
          style={{
            transform: "rotate(180deg)",
            display: "inline-block",
            marginLeft: 6,
          }}
        >
          &#8963;
        </span>
      </button>
      <Popover
        open={!!language}
        anchorEl={language}
        onClose={() => setLanguage(null)}
        anchorReference={
          anchorPosLeft && anchorPosTop ? "anchorPosition" : null
        }
        anchorPosition={{ top: anchorTop, left: anchorLeft }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        // style={{
        //   left: '50%',
        //   transform: 'translateX(-33px)',
        // }}
      >
        <div className="w-16">
          <List>
            {Object.keys(languageMap)?.map((item) => (
              <ListItem
                button
                key={item}
                onClick={() => {
                  i18next.changeLanguage(item);
                  setLanguage(null);
                  localStorage.setItem("i18nextLng", item);
                }}
              >
                {languageMap[item]}
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </>
  );
};

export default LanguageSelect;
