import { useState } from "react";
import { useTranslation } from "react-i18next";
import SendCodeSignUp from "./SendCodeSignUp";
import RegisterSignUp from "./RegisterSignUp";

const SignUpForm = () => {
  const { t } = useTranslation();
  const [dataUser, setDataUser] = useState(null)

  return (
    <div className="w-4/6 mx-auto mb-8 lg:mb-24">
      <h1 className="text-4xl lg:text-5xl font-bold text-green my-8 lg:h-44 lg:mb-8">
        {t("sign_up")}
      </h1>
      <SendCodeSignUp setDataCallback={data => setDataUser(data)} />
      {/* { dataUser == null
        ? <SendCodeSignUp setDataCallback={data => setDataUser(data)} />
        : <RegisterSignUp cancelCallback={() => setDataUser(null)} dataUser={dataUser}/>
      } */}
    </div>
  );
};

export default SignUpForm;
