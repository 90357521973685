import React from "react";
import CustBit from "../../static/landing-images/CustBit.svg";
import ButtonLink from "./LandingButton/ButtonLink";
import { useTranslation } from "react-i18next";

const CustomerHome = () => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center sm:justify-between justify-center sm:flex-row flex-col-reverse sm:mt-20 mt-10">
      <div className="sm:mt-0 mt-3">
        <img src={CustBit} alt="" className="object-cover" />
      </div>
      <div className="sm:w-5/12 w-auto">
        <h1 className="sm:text-3xl text-2xl font-bold">
          {t("not_understand_cryptocurrency")}
        </h1>
        <p className="sm:my-6 my-3 sm:text-lg text-sm">
          {t("understand_cryptocurrency__body")}
        </p>
        <div>
          <ButtonLink link="/login">{t("become_a_customer")}</ButtonLink>
        </div>
      </div>
    </div>
  );
};

export default CustomerHome;
