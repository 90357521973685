import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Zokir from "../../static/images/owner1.JPG";
import Modal from "../Modals/Modal";
import TeamModal from "../Modals/TeamModal";
import ButtonLink from "./LandingButton/ButtonLink";

const LandingPersonal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <div className="bg-grayBg sm:mt-32 mt-16">
      <div className="max-w-7xl mx-auto lg:px-0 px-5">
        <div className="flex flex-col sm:py-12 py-6">
          <div className="flex justify-center">
            <h3 className="sm:w-7/12 w-full sm:text-center text-left sm:text-2xl text-lg font-bold ">
              {t("ethicalnode")}
            </h3>
          </div>

          <div className="sm:mt-14 mt-4 flex sm:flex-row flex-col">
            <div className="sm:w-6/12 w-full">
              <img className=" rounded-md" src={Zokir} alt="" />
            </div>
            <div className="sm:w-6/12 w-full sm:ml-14 ml-0 sm:mt-0 mt-1">
              <span className="sm:text-base text-xs">{t("founder")}</span>
              <h1 className="sm:mt-4 mt-0 font-bold sm:text-2xl text-lg">
                {t("zokir")}
              </h1>
              <p className="sm:mt-3 mt-0 sm:text-xl text-sm">
                {t("landing_personal_body")}
              </p>
            </div>
          </div>

          <div className="sm:mt-14 mt-4 flex sm:flex-row flex-col items-center justify-between sm:px-7 px-5 rounded-md sm:py-5 py-4 border border-black/[/.2]">
            <span className="sm:text-xl text-lg sm:text-left text-center sm:mb-0 mb-2 font-bold">
              {t("landing_personal_team")}
            </span>
            <ButtonLink onClick={openModal}>{t("our_team")}</ButtonLink>
          </div>

          <Modal show={isOpen} onClose={closeModal}>
            <TeamModal onClick={closeModal} />
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default LandingPersonal;
