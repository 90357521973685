import { TextField } from "@mui/material";
import { withStyles } from "@mui/styles";
import slide1 from "../static/images/Frame1.png";
import slide2 from "../static/images/Frame2.png";
import owner1 from "../static/images/owner1.JPG";
import owner2 from "../static/images/owner2.jpeg";
import owner3 from "../static/images/owner3.jpg";
import owner4 from "../static/images/owner4.jpeg";
import owner5 from "../static/images/owner5.jpeg";
import owner6 from "../static/images/owner6.jpg";
import owner7 from "../static/images/owner7.JPG";

import Chart from "../static/landing-images/Chart.svg";
import Person from "../static/landing-images/Person.svg";
import Earth from "../static/landing-images/Earth.svg";

export const invests = [
  { name: "Staking", image: slide1, link: true },
  { name: "Marhaba", image: slide2 },
];

export const headerName = (path) => {
  const values = {
    "/": "mainpage",
    "/profile": "profile",
    "/about-us": "team",
  };

  return values[path];
};

export const positions = (type) => {
  const values = {
    Founder: "founder",
    Analyst: "analyst",
    "AML/KYC Manager": "aml_manager",
    Manager: "manager",
    Accountant: "accountant",
    CEO: "ceo",
    Lawyer: "lawyer",
  };

  return values[type];
};

export const ownerName = (name) => {
  const values = {
    "Zokir Ibragimiov": "zokir",
    "Zubair aka Bakhtiyar": "zubair",
    "Aydar Nizamov": "aydar",
    "Meruyert Koishybekova": "meruyert",
    "Lukman Dyshek": "lukman",
    "Yasulov Shamil": "yasulov",
    "Magomedov Magomed": "magomedov",
  };

  return values[name];
};

export const ownerInfo = (name) => {
  const values = {
    "Zokir Ibragimiov": "zokir_info",
    "Zubair aka Bakhtiyar": "zubair_info",
    "Aydar Nizamov": "aydar_info",
    "Meruyert Koishybekova": "meruyert_info",
    "Lukman Dyshek": "lukman_info",
    "Yasulov Shamil": "shamai_info",
    "Magomedov Magomed": "magomed_info",
  };

  return values[name];
};

export const team = [
  {
    name: "Zokir Ibragimiov",
    position: "Founder",
    image: owner1,
    info: "AAOIFI Certified Shari’ah Advisor and Auditor.Founder of Ethicalnode and Crypto Islam Educational Platforms. Marhaba DeFi Ambassador.",
  },
  {
    name: "Yasulov Shamil",
    position: "CEO",
    image: owner6,
    info: "Bachelor degree in SUM, Moscow (International management) ERASMUS (student exchange program) in UIB, Mallorca, Spain. Master degree in SUM, Moscow (Financial management) 4 year experience in audit in BIG4 companies. Participation in audit of companies: Universal pictures, Swatch, Metro Cash&Carry, BBDO, Carprice, Roca, BVCPG, RBK, MEDSI, Severstal. Author of a financial literacy blog, investor.",
  },
  {
    name: "Zubair aka Bakhtiyar",
    position: "Analyst",
    image: owner2,
    info: "IT professional with a background in developing financial databases and EPM solutions for Government departments and large corporations. Has been researching and investing in blockchain projects since 2018. Since September 2020, he has been focused on researching Decentralised Finance and Proof of Stake solutions and how Muslim communities can participate in these growing areas of crypto in a Shariah compliant manner.",
  },
  {
    name: "Aydar Nizamov",
    position: "AML/KYC Manager",
    image: owner3,
    info: "Graduate of the Islamic University of Medina, engaged in spreading Islam in Estonia as an imam and member of the board of the Islamic committee, also engaged in construction activities.",
  },
  {
    name: "Lukman Dyshek",
    position: "Manager",
    image: owner4,
    info: "Account Manager. From 2014 to 2021, he worked at the Multifunctional Center as a consultant for representatives of small and medium-sized businesses.",
  },
  {
    name: "Meruyert Koishybekova",
    position: "Accountant",
    image: owner5,
    info: "A finance and accounting specialist with advanced English, intermediate Arabic and five plus years of experience in international companies . Holding a qualification in Islamic Finance and some ACCA disciplines. Inspired by motivational lectures on religion, culture and social studies.",
  },

  {
    name: "Magomedov Magomed",
    position: "Lawyer",
    image: owner7,
    info: "Bachelor degree in SUM, Moscow (International management) ERASMUS (student exchange program) in UIB, Mallorca, Spain. Master degree in SUM, Moscow (Financial management) 4 year experience in audit in BIG4 companies. Participation in audit of companies: Universal pictures, Swatch, Metro Cash&Carry, BBDO, Carprice, Roca, BVCPG, RBK, MEDSI, Severstal. Author of a financial literacy blog, investor.",
  },
];

export const tabsModal = [
  { label: "translate", display: "Пeреведу сам" },
  { label: "help", display: "Помогите перевести" },
  { label: "do", display: "Сделайте за меня" },
];

export const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      borderColor: "#191D43",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#191D43",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#EDEDF4",
        borderRadius: "8px",
        borderWidth: "2px",
        height: 46,
      },
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "&:hover": {
        borderColor: "#191D43",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#191D43",
      },
    },
  },
})(TextField);

export const PasteField = withStyles({
  root: {
    "& label.Mui-focused": {},
    "& .MuiInput-underline:after": {},
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#EDEDF4",
        borderRadius: "8px",
        borderWidth: "2px",
        height: 46,
      },
      "&:hover fieldset": {
        cursor: "pointer",
        borderColor: "#EDEDF4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#EDEDF4",
      },
    },
  },
})(TextField);

export const IntegHome = [
  { label: "Digital Currency Exchanged", count: "$30B", svg: Chart },
  { label: "Trusted Wallets Investor", count: "2M+", svg: Person },
  { label: "Countries Supported", count: "195", svg: Earth },
];

export const marketHome = [
  {
    label:
      "The average (may vary!) staking yield of ~13% is taken as the basis.",
  },
  {
    label:
      "Then, both an increase in the price of a coin and its decrease are possible.",
  },
  {
    label:
      "Example: with a price increase of 20% - profitability of 36%. With a decrease of 20% - a loss of 10%.",
  },
  {
    label:
      "And they - losses (temporary) are possible due to the volatility of the crypto market. BUT why did we indicate a possible price increase by 100 and 200%",
  },
  {
    label:
      "But because the coins in which we plan to stake have already shown a price many times higher than the current values. And we expect them to grow to their previous values in the long term.",
  },
];

export const differentHome = [
  {
    label: "Навыки и умения",
  },
  {
    label: "Время и управление",
  },
  {
    label: "Умение отбирать качественные проекты ",
  },
  {
    label: "Умение отбирать качественные проекты ",
  },
  {
    label: "Самое главное - умение определять дозволенность проектов",
  },
  {
    label:
      "Периодически доходность выбранного сервиса может падать, мы отслеживаем такие изменения и оперативно перевозим средства на сервис, где доходность выше",
  },
];

export const accordionItems = [
  { name: "The minimum investment amount is?", content: "hello1" },
  {
    name: "For how long are funds staked and when/how can I withdraw?",
    content: "hello2",
  },
  { name: "Can I immediately reinvest?", content: "hello3" },
  {
    name: "What is the potential return and for what period (annual or for the staking period)?",
    content: "hello4",
  },
  {
    name: "Will information be disclosed which tokens are staked?",
    content: "hello5",
  },
  {
    name: "Is it possible to withdraw in tokens that were staked?",
    content: "hello6",
  },
  {
    name: "Is it possible to withdraw in tokens that were staked1?",
    content: "hello6",
  },
  {
    name: "Is it possible to withdraw in tokens that were stake2?",
    content: "hello6",
  },
  {
    name: "Is it possible to withdraw in tokens that were stake3?",
    content: "hello6",
  },
  {
    name: "Is it possible to withdraw in tokens that were stake4?",
    content: "hello6",
  },
  {
    name: "Is it possible to withdraw in tokens that were stake5?",
    content: "hello6",
  },
  {
    name: "Is it possible to withdraw in tokens that were stake6?",
    content: "hello6",
  },
  {
    name: "Is it possible to withdraw in tokens that were stake7?",
    content: "hello6",
  },
];

export const investSelectData = [
  { label: "Переведу сам" },
  { label: "Помогите перевести" },
  { label: "Сделайте за меня" },
];


export const conventionTabs = [
  { name: 'Соглашение', id: 1, view: 1 },
  { name: 'Политика', id: 2, view: 2 },
  { name: 'Положение', id: 3, view: 3 },
]
