import React from 'react'

const CustomerHandling = () => {
    return (
        <div>
            <div>
                <h1 className=" text-sm sm:text-xl font-bold text-center mb-2">
                    ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ Ethicalnode.com
                </h1>
                <p className="text-xs sm:text-lg">
                    Настоящее пользовательское соглашение (далее по тексту также –
                    «Соглашение») является публичной офертой (предложением) islaminvest OÜ
                    (зарегистрирована в Эстонии - код регистрации 16419155) (далее по тексту
                    также – «Ethicalnode.com», «Платформа») любому заинтересованному
                    физическому лицу (далее по тексту также – «Пользователь») заключить его
                    на нижеследующих условиях.
                </p>
                <p className="text-xs sm:text-lg mt-2">
                    Соглашение вступает в силу с момента его размещения на сайте Платформы в
                    сети «Интернет» по адресу: https://ethicalnode.com (далее по тексту также
                    – «Сайт») и действует до его отзыва.
                </p>
                <p className="text-xs sm:text-lg mt-2">
                    Платформа вправе изменить или отозвать Соглашение в одностороннем
                    порядке. Все изменения вступают в силу и считаются доведенными до
                    сведения Пользователя в момент размещения на вышеуказанной
                    Интернет-странице.
                </p>
                <p className="text-xs sm:text-lg mt-2">
                    Заполнение полей при регистрации на Сайте Ethicalnode.com и/или получение
                    доступа к сервисам и услугам Платформы и/или их использование и/или
                    проставление отметки «Я согласен» в специальном поле признается полным и
                    безоговорочным акцептом настоящего Соглашения, а также Политики
                    конфиденциальности Ethicalnode.com (https://ethicalnode.com).
                </p>
                <p className="text-xs sm:text-lg mt-2">
                    При использовании различных функций, сервисов и услуг Ethicalnode.com
                    могут применяться особые дополнительные условия. В случае любого
                    противоречия или несоответствия между условиями настоящего Соглашения и
                    особыми дополнительными условиями, последние имеют преимущественную силу
                    и подлежат применению. Ваше пользование данными услугами является
                    принятием таких особых дополнительных условий.
                </p>
            </div>
            <div className=" pl-7">
                <p className="text-xs sm:text-lg font-bold mt-2">
                    Пользуясь услугами Ethicalnode.com, Вы признаете и соглашаетесь с тем,
                    что:
                </p>

                <div className=" flex items-start">
                    <span className="text-xs sm:text-lg font-bold pt-1"> a. </span>
                    <p className="text-xs sm:text-lg font-bold mt-1 ml-4">
                        цифровые монеты (криптовалюта) являются высокорискованным
                        инструментом;
                    </p>
                </div>

                <div className=" flex items-start">
                    <span className="text-xs sm:text-lg font-bold pt-1">b. </span>
                    <p className="text-xs sm:text-lg font-bold mt-1 ml-4">
                        цифровые монеты (криптовалюта) являются инвестиционным инструментом
                        с большой волатильностью;
                    </p>
                </div>
                <div className=" flex items-start">
                    <span className="text-xs sm:text-lg font-bold pt-1">c. </span>
                    <p className="text-xs sm:text-lg font-bold mt-1 ml-4">
                        Вы знаете о рисках, связанных с операциями с цифровыми монетами
                        (криптовалютой) и их производными;
                    </p>
                </div>
                <div className=" flex items-start">
                    <span className="text-xs sm:text-lg font-bold pt-1">d. </span>
                    <p className="text-xs sm:text-lg font-bold mt-1 ml-4">
                        Вы принимаете на себя все риски, связанные с использованием услуг
                        Ethicalnode.com и сделками с цифровыми монетами (криптовалютой), и их
                        производными;
                    </p>
                </div>
                <div className=" flex items-start">
                    <span className="text-xs sm:text-lg font-bold pt-1">e. </span>
                    <p className="text-xs sm:text-lg font-bold mt-1 ml-4">
                        Вы самостоятельно несете ответственность за несанкционированное
                        использование имени пользователя и пароля от вашей учетной записи
                        Ethicalnode.com;
                    </p>
                </div>
                <div className=" flex items-start">
                    <span className="text-xs sm:text-lg font-bold pt-1">f. </span>
                    <p className="text-xs sm:text-lg font-bold mt-1 ml-4">
                        Ethicalnode.com не несет ответственность за любые риски, прямые или
                        косвенные убытки, в том числе, реальный ущерб и упущенную выгоду,
                        произошедшие в результате оказания услуг Ethicalnode.com и/или
                        совершения сделок с цифровыми монетами (криптовалютой) и их
                        производными;
                    </p>
                </div>
                <div className=" flex items-start">
                    <span className="text-xs sm:text-lg font-bold pt-1">g. </span>
                    <p className="text-xs sm:text-lg font-bold mt-1 ml-4">
                        хранение, торговля или использование цифровых монет (криптовалюты)
                        сопряжено со значительными рисками. Ethicalnode.com не дает
                        финансовые, юридические или налоговые консультации, и Сайт не может
                        рассматриваться как предложение или побуждение к принятию каких-либо
                        финансовых решений.
                    </p>
                </div>
            </div>
            <div className="mt-5">
                <p className=" flex items-center text-xs sm:text-lg font-bold">
                    <span className=" mr-4">1.</span>
                    Порядок и условия регистрации и пользования учетной записью
                    Ethicalnode.com.
                </p>

                <div className="pl-3">
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">1.1</span>
                        Пользователи обязаны подать заявку на создание учетной записи и
                        пройти регистрацию на Сайте Платформы по адресу:
                        https://ethicalnode.com/registration, прежде чем использовать услуги
                        Ethicalnode.com. При регистрации необходимо указать полную и
                        достоверную информацию, в соответствии в п. 1.4. настоящего
                        Соглашения, а также принять условия настоящего Соглашения, Политики
                        конфиденциальности и иных правил, а также особых дополнительных
                        условий. Ethicalnode.com вправе в одностороннем порядке без
                        объяснения причин отказать Пользователю в регистрации и открытии
                        учетной записи.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className="mr-4">1.2</span>
                        Пользователи обязуются предоставить полную и достоверную информацию
                        при регистрации на Сайте Ethicalnode.com и соглашаются своевременно
                        обновлять любую предоставленную информацию для обеспечения точности
                        и обработки информации Платформой в соответствии с Положением об
                        обработке персональных данных (https://ethicalnode.com).
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className="mr-4">1.3</span>
                        Регистрируясь для использования учетной записи Ethicalnode.com, Вы
                        заявляете и гарантируете, что:
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2 pl-5">
                        <span className="mr-4 ">1.3.1 </span>
                        Вам не менее 18 (восемнадцати) лет;
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2 pl-5">
                        <span className="mr-4">1.3.2 </span>
                        Вы обладаете полной дееспособностью и правоспособностью в
                        соответствии с действующим законодательством, а также обладаете
                        достаточными полномочиями для принятия условий настоящего
                        Соглашения;
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2 pl-5">
                        <span className="mr-4">1.3.3 </span>
                        Вы ранее не были удалены или отстранены от услуг Ethicalnode.com;
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2 pl-5">
                        <span className="mr-4">1.3.4 </span>
                        использование Вами услуг Ethicalnode.com не будет нарушать какие-либо
                        применимые к Вам законы и правила, включая, помимо прочего,
                        положения о борьбе с отмыванием денег, коррупцией и финансированием
                        терроризма.
                    </p>

                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className="mr-4">1.4</span>
                        Регистрируя учетную запись на Сайте Ethicalnode.com, Вы соглашаетесь
                        предоставить необходимую личную информацию для проверки личности, а
                        также соглашаетесь на обработку персональных данных. Такая
                        информация будет использоваться для проверки личности Пользователей,
                        выявления следов отмывания денег, финансирования терроризма,
                        мошенничества и других финансовых преступлений через Ethicalnode.com
                        или для других законных целей, заявленных Ethicalnode.com. Платформа
                        осуществляет сбор и обработку персональных данных Пользователей в
                        соответствии с Политикой конфиденциальности. Помимо предоставления
                        такой информации, Пользователи разрешают Ethicalnode.com вести учет,
                        передачу, хранение и использование своих персональных данных в
                        течение периода, в течение которого учетная запись Пользователя
                        активна, а также в течение 5 (пяти) лет после закрытия учетной
                        записи в соответствии с мировыми стандартами хранения данных. Кроме
                        того, Пользователи разрешают Ethicalnode.com самостоятельно или с
                        привлечением третьих лиц проверять подлинность предоставленной
                        информации и данных, чтобы подтвердить личность Пользователей или
                        защитить Пользователя и/или Ethicalnode.com от финансовых
                        преступлений, таких как мошенничество. Информация, необходимая для
                        подтверждения личности Пользователя, может включать, помимо прочего,
                        фамилию, имя, отчество (при наличии), адрес электронной почты,
                        контактную информацию, контактный номер телефона, имя пользователя,
                        дату рождения и другую информацию, собранную во время регистрации
                        учетной записи. Предоставляя требуемую информацию, Пользователи
                        подтверждают и несут ответственность за ее достоверность и полноту.
                    </p>

                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className="mr-4">1.5</span>
                        Учетная запись Ethicalnode.com может использоваться только владельцем
                        учетной записи. Ethicalnode.com оставляет за собой право
                        приостановить, заморозить или отменить использование учетных записей
                        Ethicalnode.com лицами, не являющимися владельцами учетных записей.
                        Если Вам стало известно о несанкционированном использовании вашего
                        имени пользователя и пароля, необходимо немедленно уведомить об этом
                        Ethicalnode.com. Платформа не несет ответственности за любые убытки
                        или ущерб, возникшие в результате использования учетной записи
                        Ethicalnode.com вами или любой третьей стороной с вашего разрешения
                        или без него.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className="mr-4">1.6</span>
                        При условии регистрации учетной записи Ethicalnode.com и соблюдения
                        Пользователем условий настоящего Соглашения, Платформа предоставляет
                        отзывную, ограниченную, безвозмездную, неисключительную и не
                        подлежащую сублицензированию лицензию на доступ к услугам
                        Ethicalnode.com и их использование для личных, не связанных с
                        предпринимательской деятельностью, целей.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className="mr-4">1.7</span>
                        После завершения регистрации Пользователь получает доступ к учетной
                        записи на Сайте Ethicalnode.com, вход в которую осуществляется по
                        логину и паролю, заданным Пользователем самостоятельно.
                    </p>
                </div>
            </div>

            <div className="mt-5">
                <p className=" flex items-center text-xs sm:text-lg font-bold">
                    <span className=" mr-4">2.</span>
                    Обработка данных
                </p>

                <div className="pl-3">
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">2.1</span>
                        Пользователь дает Ethicalnode.com свое согласие на обработку его
                        персональных данных, указываемых Пользователем в интерактивных полях
                        Сайта в ходе прохождения процедуры регистрации на Сайте, а также
                        любой иной информации, предоставляемой Пользователем в процессе
                        использования Сайта Ethicalnode.com.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">2.2</span>
                        Согласие Пользователя на обработку предоставленных данных,
                        выраженное в настоящем Соглашении, действует бессрочно и, может быть
                        в любой момент отозвано Пользователем с учетом положений, изложенных
                        в п. 1.4. Соглашения, путём письменного обращения к Платформе и с
                        одновременным приостановлением оказания услуг Пользователю.
                        Ethicalnode.com обязуется в разумные сроки немедленно прекратить
                        обработку предоставленных данных после получения соответствующего
                        требования Пользователя.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">2.3</span>
                        Порядок и условия обработки персональных данных Пользователей
                        определяется Положением об обработке персональных данных, доступным
                        для ознакомления на Сайте Ethicalnode.com по адресу:
                        https://ethicalnode.com.
                    </p>
                </div>
            </div>

            <div className="mt-5">
                <p className=" flex items-center text-xs sm:text-lg font-bold">
                    <span className=" mr-4">3.</span>
                    Зачисление средств на кошелек платформы Ethicalnode.com.
                </p>

                <div className="pl-3">
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">3.1</span>
                        Пользователи вносят средства в кошелек платформы Ethicalnode.com на
                        Сайте (далее по тексту также – «Кошелек»), для этого необходимо
                        осуществить перевод средств на адрес Кошелька в USDT (минимальная
                        сумма перевода - 100 USDT), используя Hash транзакции, после чего
                        необходимо отразить данную транзакцию на Сайте. Пользователи могут
                        вносить только свои собственные средства со своего собственного
                        счета.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">3.2</span>
                        Указанная транзакция попадает на проверку администратора Платформы в
                        случае, если при переводе Hash, сумма и адрес Кошелька указаны
                        верно, данные средства отражаются в личном кабинете (под личным
                        кабинетом подразумевается детализация вкладов стейкинга, которая
                        отображается после нажатия кнопки «подробнее» в разделе стейкинг) на
                        Сайте как сумма пополнения. Если параметры платежа не соответствуют
                        информации, полученной по Hash транзакции, то вклад Пользователя
                        считается недействительным. При этом Пользователь несет
                        ответственность за правильное указание всех параметров платежа. Если
                        средства Пользователя переведены не по адресу Кошелька, указанному
                        на Сайте, Платформа не возвращает Пользователю средства.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">3.3</span>
                        Кошелек не является депозитным или инвестиционным счетом,
                        следовательно, застраховать средства в данном кошельке невозможно.
                    </p>

                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">3.4</span>
                        Осуществив зачисление средств на Кошелек, Пользователь безусловно
                        подтверждает и соглашается с порядком и условиями оказания услуг
                        Ethicalnode.com, изложенными в разделе 4 настоящего Соглашения, при
                        этом принимая все риски, связанные с утратой средств, на себя.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">3.5</span>
                        После зачисления средств и их размещения (вклада) на Кошельке
                        Платформы, указанному вкладу присваивается «ID вклада» (уникальный
                        номер вклада Пользователя), по которому возможно отслеживать
                        аналитику по вкладу в личном кабинете на Сайте.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">3.6</span>
                        Днем зачисления средств на Кошелек считается день, в который
                        средства Пользователя поступили на Кошелек Платформы. В случае если
                        зачисление средств произошло в нерабочий день или после 17:00 (по
                        московскому времени) в рабочий день, то днем зачисления средств на
                        Кошелек считается следующий рабочий день.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">3.7</span>
                        После успешной регистрации учетной записи и зачисления средств на
                        Кошелек Платформа вправе начать инвестировать.
                    </p>
                </div>
            </div>

            <div className="mt-5">
                <p className=" flex items-center text-xs sm:text-lg font-bold">
                    <span className=" mr-4">4.</span>
                    Услуги Ethicalnode.com.
                </p>

                <div className="pl-3">
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">4.1</span>
                        После завершения регистрации и подтверждения личности учетной записи
                        Пользователя, Вам становятся доступными различные услуги
                        Ethicalnode.com, включая, помимо прочего, и предоставление средств
                        Платформе для их стейкинга. Ethicalnode.com имеет право:
                    </p>

                    <p className=" flex items-start text-xs sm:text-lg mt-2 pl-4">
                        <span className=" mr-4">4.1.1 </span>
                        предоставлять, изменять или прекращать, по своему усмотрению, любые
                        услуги на основе своего плана развития;
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2 pl-4">
                        <span className=" mr-4">4.1.2 </span>
                        запрещать Пользователям, по своему усмотрению, использовать
                        какие-либо услуги в соответствии с соответствующими правилами
                        Ethicalnode.com.
                    </p>

                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">4.2</span>
                        Ethicalnode.com на основании самостоятельного анализа приобретает на
                        все поступившие от Пользователя средства токены отобранных проектов
                        и ставит их на стейкинг, под которым подразумевается получение
                        пассивного заработка за счет участия цифровых монет (криптовалюта)
                        Пользователя на Платформе в подтверждении новых блоков блокчейна
                        (далее по тексту также – «Стейкинг»). Участвуя в Стейкинге,
                        Пользователи должны учитывать, что:
                    </p>

                    <p className=" flex items-start text-xs sm:text-lg mt-2 pl-4">
                        <span className=" mr-4">4.2.1 </span>стейкинг является возмездной
                        услугой, за которую Ethicalnode.com получает комиссии в размере,
                        установленном Платформой и указанном на Сайте
                        (https://ethicalnode.com);
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2 pl-4">
                        <span className=" mr-4">4.2.2 </span>Ethicalnode.com не гарантирует
                        доходы Пользователей в рамках услуг Стейкинга;
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2 pl-4">
                        <span className=" mr-4">4.2.3 </span>Ethicalnode.com имеет право
                        самостоятельно инициировать или прекращать Стейкинг любых цифровых
                        монет (криптовалюты) или изменять правила Стейкинга по своему
                        усмотрению;
                    </p>

                    <p className=" flex items-start text-xs sm:text-lg mt-2 pl-4">
                        <span className=" mr-4">4.2.4 </span>стейкинг является личным
                        добровольным действием Пользователя, и только Пользователь несет
                        ответственность за все риски, обязательства, последствия и/или
                        убытки, возникающие в результате использования данной услуги;
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2 pl-4">
                        <span className=" mr-4">4.2.5 </span>Ethicalnode.com не является
                        банком, депозитарным учреждением, банком-кастодианом или доверенным
                        лицом, и активы Пользователей, находящиеся на Платформе не
                        застрахованы какими-либо частными или государственными видами
                        страхования, а также они не покрываются какой-либо компенсационной
                        схемой в случай частичной или полной их потери.
                    </p>

                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">4.3</span>В течение 2 (двух) рабочих дней
                        (при значительной коррекции данный срок может быть увеличен) с
                        момента зачисления средств на Кошелек Платформы (п. 3.3. настоящего
                        Соглашения), Ethicalnode.com осуществляет проверку поступивших
                        денежных средств и самостоятельно производит покупку цифровых монет
                        (криптовалюты) для размещения их на Стейкинг.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">4.4</span>
                        На все зачисленные средства Пользователя Платформа приобретает
                        токены в равных долях, которые переводятся на адреса нативных
                        (совместимых) кошельков для каждого токена, где осуществляется
                        Стейкинг токенов.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">4.5</span>
                        После размещения на Стейкинг приобретенных цифровых монет
                        (криптовалюты), в личном кабинете Пользователя по каждому отдельному
                        вкладу Пользователя отображается: баланс (с частотой обновления
                        примерно раз в день), дата вложения средств, текущая сумма по вкладу
                        пользователя (на сайте столбец «доступно для вывода»), финансовый
                        результат (в абсолютном выражении), изменение (финансовый результат
                        в процентном выражении ), количество дней до разблокировки
                        (подробнее см. в приложении), и действия доступные по каждому вкладу
                        – разлок/unstake и вывести средства/withdraw (подробнее см. пункт
                        6.2) Также в личном кабинете Пользователя по всем активным вкладам
                        Пользователя отображается обобщенная/сводная информация: сумма
                        вложений, текущая сумма активов, изменение (в процентном
                        соотношении), сумма активов, доступных для вывода.
                    </p>
                </div>
            </div>

            <div className="mt-5">
                <p className=" flex items-center text-xs sm:text-lg font-bold">
                    <span className=" mr-4">5.</span>
                    Предупреждение о рисках.
                </p>

                <div className="pl-3">
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">5.1</span>
                        Риски, связанные с законами, правилами и положениями различных
                        стран: Сделки с цифровыми монетами (криптовалютой) могут быть в
                        любое время приостановлены или запрещены в конкретных странах, в
                        результате принятия или изменения соответствующих законов, положений
                        и правил. Вы понимаете и соглашаетесь с тем, что все риски, убытки
                        или последствия, возникающие, в связи с этим, Пользователи несут
                        сами.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">5.2</span>
                        Риски, связанные с конфиденциальностью:
                        <br />В целях соблюдения мировых отраслевых стандартов, местных
                        нормативов и соответствия правительственным постановлениям о борьбе
                        с отмыванием денег, процедуре идентификации и борьбе с
                        финансированием терроризма Ethicalnode.com придерживается требований
                        правительств или компетентных органов стран о предоставлении
                        соответствующей информации о клиентах в тех случаях, когда
                        правительства или компетентные органы представляют соответствующие
                        документы для расследования. В таком случае Ethicalnode.com не несет
                        ответственность за любое раскрытие информации или потерю активов.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">5.3</span>
                        Риски, связанные с использованием услуг Стейкинга: Использование
                        услуг Стейкинга является личным добровольным действием Пользователя,
                        и только Пользователь несет ответственность за все риски,
                        обязательства, последствия и/или убытки, возникающие в результате
                        использования данной услуги. С учетом вышеизложенного Вы
                        подтверждаете, что полностью осведомлены и осознаете, что
                        использование услуг Стейкинга несет в себе значительные риски.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">5.4</span>
                        Риски, связанные с наступлением форс-мажорных обстоятельств: Любые
                        риски, убытки и/или негативные последствия, возникшие в результате
                        форс-мажорных обстоятельств, несет Пользователь. Под форс-мажорными
                        обстоятельствами понимаются внешние, чрезвычайные и неизбежные
                        обстоятельства, которые возникают не по воле сторон настоящего
                        Соглашения, и наступление и последствие которых стороны не смогли бы
                        предотвратить, действуя добросовестно и разумно, включая, в
                        частности, стихийные бедствия, военные действия, массовые
                        беспорядки, забастовки, препятствующие исполнению сторонами
                        обязательств по настоящему Соглашению и другие действия, находящиеся
                        вне контроля или возможностей Платформы.
                    </p>
                </div>
            </div>

            <div className="mt-5">
                <p className=" flex items-center text-xs sm:text-lg font-bold">
                    <span className=" mr-4">6.</span>
                    Порядок приостановки оказания услуг и вывода средств.
                </p>

                <div className="pl-3">
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">6.1</span>
                        Пользователь вправе в любое время направить Ethicalnode.com
                        уведомление о приостановке оказания услуг Стейкинга и выводе
                        имеющихся средств по истечении срока, указанного в п. 6.2.
                        настоящего Соглашения, для этого Пользователю необходимо кликнуть
                        кнопку «Разлок/Unstake» в личном кабинете. После этого на адрес
                        электронной почты Пользователя поступит письмо с указанием точной
                        даты, когда средства будут доступны к выводу.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">6.2</span>
                        Ethicalnode.com предоставляет опцию вывода средств по прошествии 31
                        (тридцати одного) календарного включительно дня с момента нажатия
                        Пользователем кнопки «Разлок/Unstake». По истечению указанного срока
                        в Кошельке Пользователя отобразится кнопка «Вывести
                        средства/Withdraw». Нажатие на данную кнопку означает размещение
                        заявки на вывод средств.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">6.3</span>
                        При осуществлении запроса на вывод Пользователю будет предложено
                        внести адрес, на который Платформа осуществит перевод средств в
                        USDT. Помимо этого, на контактный номер телефона Пользователя,
                        указанный при регистрации учетной записи, должен поступить код
                        подтверждения, который необходимо ввести в соответствующее поле для
                        вывода средств.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">6.4</span>
                        Днем заявки на вывод средств считается день, в который Пользователь
                        оставил заявку на вывод. В случае если заявка на вывод средств
                        подана в нерабочий или выходной день, или после 17:00 (по
                        московскому времени) рабочего дня, то днем фактического принятия
                        заявки на вывод считается рабочий день, следующий за днем подачи
                        заявки на вывод.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">6.5</span>
                        Средства Пользователя будут выведены в течение 2 (двух) рабочих дней
                        со дня поступления заявки на вывод средств с указанием адреса
                        кошелька для вывода средств. При этом размер средств, подлежащих
                        выводу, определяется на основании курса, действующего на дату
                        фактического вывода средств Пользователя.
                    </p>
                </div>
            </div>

            <div className="mt-5">
                <p className=" flex items-center text-xs sm:text-lg font-bold">
                    <span className=" mr-4">7.</span>
                    Ответственность.
                </p>

                <div className="pl-3">
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">7.1</span>
                        Пользователи не вправе совершать какие-либо действия, направленные
                        на технологическое извлечение или копирование информации из Сайта
                        Ethicalnode.com в обход средств доступа, непосредственно
                        предоставляемых Пользователям на Сайте. В случае нарушения
                        соответствующего требования, Пользователи несут ответственность в
                        соответствии с положениями действующего законодательства Российской
                        Федерации.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">7.2</span>
                        Ethicalnode.com не несет ответственности перед Пользователями за
                        любые косвенные, случайные, непреднамеренные убытки (включая, но не
                        ограничиваясь, вред, вызванный потерей данных или ущерб, причиненный
                        чести, достоинству или деловой репутации), если соответствующие
                        убытки возникли в связи с использованием Сайта, а также иных
                        материалов, к которым Пользователи получили доступ в процессе
                        использования Сайта. При этом размер возмещения, причитающегося
                        Пользователю в связи с каким-либо нарушением со стороны
                        Ethicalnode.com, не может превышать размера капитала Пользователя
                        доступного для вывода на день возмещения.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">7.3</span>
                        Ethicalnode.com не несет ответственность перед Пользователями за
                        любые косвенные или прямые убытки, в том числе прямой ущерб и
                        упущенную выгоду, если такие убытки возникли в результате оказания
                        Ethicalnode.com услуг Стейкинга или иных услуг. Пользуясь услугами
                        Ethicalnode.com, Пользователь подтверждает и принимает на себя полную
                        ответственность за риски, связанные с частичной или полной потерей
                        средств.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">7.4</span>
                        Ethicalnode.com не несет ответственности за безопасность каналов
                        связи, программных или аппаратных средств, которые используются
                        Пользователями для доступа к Сайту.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">7.5</span>
                        Ethicalnode.com не несет какой-либо ответственности в связи с
                        убытками, которые вызваны недостоверностью информации, указанной или
                        размещенной Пользователем, или третьими лицами в процессе
                        регистрации на Сайте.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">7.6</span>
                        Если во время использования Сайта Пользователь осуществляет переход
                        по внешней ссылке, обеспечивающей переход Пользователя на сайт
                        третьего лица, Ethicalnode.com не несет ответственности за какие-либо
                        прямые или косвенные убытки, понесенные Пользователем в связи с
                        таким переходом.
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">7.7</span>
                        Учитывая принципы построения и функционирования сети «Интернет»,
                        услуги Ethicalnode.com предоставляются «как есть», это означает, что
                        Ethicalnode.com не предоставляет каких-либо гарантий в отношении
                        оказываемых услуг, в частности, Ethicalnode.com не гарантирует
                        Пользователю, что:
                    </p>

                    <p className=" flex items-start text-xs sm:text-lg mt-2 pl-4">
                        <span className=" mr-4">7.7.1 </span>
                        оказываемые Ethicalnode.com услуги, в том числе, но не ограничиваясь,
                        услуги Стейкинга, их прямой или косвенный эффект и качество будут
                        соответствовать требованиям и целям Пользователя;
                    </p>

                    <p className=" flex items-start text-xs sm:text-lg mt-2 pl-4">
                        <span className=" mr-4">7.7.2 </span>
                        сервис на Сайте будет предоставляться непрерывно, надежно и без
                        ошибок;
                    </p>
                    <p className=" flex items-start text-xs sm:text-lg mt-2 pl-4">
                        <span className=" mr-4">7.7.3 </span>
                        результаты, полученные вследствие оказания услуг Ethicalnode.com,
                        будут точными, надежными и соответствовать ожиданиям Пользователя.
                    </p>
                </div>
            </div>

            <div className="mt-5">
                <p className=" flex items-center text-xs sm:text-lg font-bold">
                    <span className=" mr-4">8.</span>
                    Заключительные положения.
                </p>

                <div className="pl-3">
                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">8.1</span>
                        Любые споры, связанные с заключением, изменением, исполнением или
                        прекращением настоящего Соглашения, подлежат разрешению в
                        соответствии с законодательством Российской Федерации.
                    </p>

                    <p className=" flex items-start text-xs sm:text-lg mt-2">
                        <span className=" mr-4">8.2</span>
                        Ethicalnode.com вправе вносить изменения в условия настоящего
                        Соглашения посредством опубликования текста измененной редакции
                        настоящего Соглашения на Сайте. Соответствующие изменения вступают в
                        силу с момента опубликования измененной редакции настоящего
                        Соглашения. Продолжая использование Сайта после вступления в силу
                        соответствующих изменений, Пользователь выражает свое согласие с
                        условиями Соглашения в новой редакции.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default CustomerHandling
