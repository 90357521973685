import { useTranslation } from "react-i18next";
import MessageSupport from "./MessageSupport";
import SupportUserForm from "./SupportUserForm";

const SupportUser = () => {
  const { t } = useTranslation();

  return (
    <div className="sm:w-4/6 md:w-3/6 mx-auto text-center pt-5 pb-12">
      <h1 className="text-blue text-2xl md:text-3xl xl:text-4xl font-bold pb-3 md:pb-5">{t("support_title")}</h1>
      <p className="pb-4">{t('support_description')}</p>
      <SupportUserForm />
      <MessageSupport />
    </div>
  );
};

export default SupportUser;
