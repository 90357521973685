import { Button } from "@mui/material";
import { CssTextField } from "../../static/consts";
import { useFormik } from "formik";
import { useState } from "react";
import Modal from "../Modals/Modal";
import SuccessModal from "../Modals/SuccessModal";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import CallService from "../../services/CallService";

const ForgotPasswordModal = ({ onClick }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [wrongPass, setWrongPass] = useState(false);
  const closeModal = () => setIsModalOpen(false);
  const { t } = useTranslation();

  const validationSchema = yup.object({
    email: yup
      .string(t("enter_email"))
      .email(t("valid_email"))
      .required(t("email_required")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async ({ email }) => {
      const data = await CallService.ForgotPassword(email);
      if (data.message === "success") {
        setIsModalOpen(true);
        setWrongPass(false);
      } else if (data === 400) {
        setWrongPass(true);
      }
    },
  });

  return (
    <div>
      <div className="text-center h-full">
        <div className="mx-auto py-6">
          <div className="w-4/6 mx-auto">
            <h2 className="text-blue font-bold text-xl pb-4">
              {t("password_recovery")}
            </h2>
            <p className="text-blue font-normal text-sm pb-4">
              {t("forgot_password_text")}
            </p>
          </div>
          <form onSubmit={formik.handleSubmit} className="w-11/12 mx-auto">
            <div className="h-16 w-11/12 mx-auto">
              <CssTextField
                fullWidth
                id="email"
                name="email"
                placeholder={t("enter_email")}
                inputProps={{
                  style: {
                    padding: 10,
                  },
                }}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>

            <p
              className={wrongPass ? "text-red text-left ml-8 mb-4" : "hidden"}
            >
              {t("email_not_match")}
            </p>

            <div className="w-full">
              <Button
                variant="contained"
                onClick={onClick}
                style={{
                  borderRadius: 4,
                  height: 60,
                  width: "30%",
                  marginRight: 20,
                  backgroundColor: "#EDEDF4",
                  fontSize: "16px",
                  textTransform: "none",
                  color: "#191D43",
                  fontWeight: "600",
                  display: "inline-block",
                }}
              >
                {t("cancel")}
              </Button>
              <Button
                variant="contained"
                type="submit"
                style={{
                  borderRadius: 4,
                  height: 60,
                  width: "58%",
                  backgroundColor: "#4BCBC8",
                  textTransform: "none",
                  color: "#FFF",
                  fontSize: 14,
                  fontWeight: "600",
                  display: "inline-block",
                }}
              >
                {t("restore_password")}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <Modal show={isModalOpen} onClose={closeModal}>
        <SuccessModal onClick={onClick} text={t("success_send_pass")} />
      </Modal>
    </div>
  );
};

export default ForgotPasswordModal;
