import { useTranslation } from "react-i18next";
import { ownerInfo, ownerName, positions } from "../../static/consts";

const AboutUsCard = ({ name, position, image, info }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full sm:w-1/2 lg:w-1/3 xl:w-56 2xl:w-64 mx-auto my-6 xl:my-8">
      <div className="mx-1 bg-white rounded shadow pt-6 pb-6 xl:pt-8 xl:pb-8">
        <img
          src={image}
          alt="mobile"
          className="h-44 w-10/12 xl:w-9/12 mx-auto rounded-lg shadow mb-4 object-cover"
        />
        <div className="mb-5">
          <p className="text-center text-blue text-xl font-bold mx-2">
            {t(ownerName(name))}
          </p>
          <p className="text-center text-gray">{t(positions(position))}</p>
        </div>
        <div className="text-gray text-center lg:text-sm w-11/12 xl:w-10/12 mx-auto text-ellipsis overflow-hidden h-400 ">
          <p>{t(ownerInfo(name))}</p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsCard;
