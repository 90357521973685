import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div>
            <div>
                <h1 className=" text-sm sm:text-xl font-bold text-center mb-2">
                    Политика конфиденциальности Ethicalnode.com
                </h1>
                <p className="text-xs sm:text-lg">
                    Настоящая Политика конфиденциальности (далее по тексту также – Политика) применяется ко всем Пользователям Ethicalnode.com.
                </p>
                <p className="text-xs sm:text-lg pt-2 sm:pt-4">
                    Пользователи прямо соглашаются на обработку своих персональных данных, как это описано в настоящей Политике. Обработка означает любую операцию с персональными данными независимо от используемых средств и процедуры, в частности сбор, запись, систематизацию, накопление, хранение, уточнение, обновление, изменение и извлечение персональных данных, а также их использование, передачу, распространение, предоставление, доступ, обезличивание, блокирование, удаление и уничтожение.
                </p>
                <p className="text-xs sm:text-lg  pt-2 sm:pt-4">
                    Сервис предоставляется Ethicalnode.com, дочерними компаниями, концессионерами, подрядчиками и лицензиатами (связанные компании). Ethicalnode.com является контролером данных, отвечает за цели обработки персональных данных Пользователей и может передавать персональные данные или поручать их обработку любой из связанных компаний в порядке, предусмотренном законодательством.
                </p>
            </div>

            <div className='mt-2 sm:mt-5'>
                <h1 className="text-sm sm:text-xl font-bold text-center mb-2">
                    Сбор персональных данных
                </h1>
                <p className="text-xs sm:text-lg">
                    Ethicalnode.com, предлагая свои услуги, нуждается в сведениях о коммуникационных реквизитах Пользователей. При этом Ethicalnode.com запрашивает у Пользователя минимальный объём этих сведений, чтобы поддерживать связь с клиентом:
                </p>
                <p className="text-xs sm:text-lg pt-2 sm:pt-5">
                    фамилия, имя, отчество Пользователя;
                </p>
                <p className="text-xs sm:text-lg  pt-2 sm:pt-5">
                    мобильный телефон;
                </p>

                <p className="text-xs sm:text-lg  pt-2 sm:pt-5">
                    адрес электронной почты.
                </p>
                <p className="text-xs sm:text-lg  pt-2 sm:pt-5">
                    В процессе обработки услуг Ethicalnode.com может собирать и хранить следующую личную информацию, включая персональные данные:
                </p>

                <div className='pl-2 sm:pl-5'>
                    <p className="flex items-center text-xs sm:text-lg pt-3 sm:pt-2">
                        <span className=" mr-2">1.</span>
                        сведения об устройстве входа на сайт;
                    </p>

                    <p className="flex items-center text-xs sm:text-lg  pt-3 sm:pt-2">
                        <span className=" mr-2">2.</span>
                        сведения о времени и датах визитов на сайт;
                    </p>

                    <p className="flex items-center text-xs sm:text-lg  pt-3 sm:pt-2">
                        <span className=" mr-2">3.</span>
                        сведения о просмотренных страницах;
                    </p>
                    <p className="flex items-center text-xs sm:text-lg  pt-3 sm:pt-2">
                        <span className=" mr-2">4.</span>
                        паспортные данные;
                    </p>
                    <p className="flex items-center text-xs sm:text-lg  pt-3 sm:pt-2">
                        <span className=" mr-2">5.</span>
                        данные банковской карты;
                    </p>
                </div>

                <p className="text-xs sm:text-lg pt-2 sm:pt-5">
                    иную информацию о взаимодействии Пользователя с информационными системами Ethicalnode.com и её партнёров, результаты сбора статистики посещений сайтов Ethicalnode.com и общедоступной информации о программах и устройствах, используемых для такового доступа, включая данные о геолокации или IP-адресе Пользователя.
                </p>

            </div>

            <div className='mt-2 sm:mt-5'>
                <h1 className=" text-sm sm:text-xl font-bold text-center mb-2">
                    Использование персональных данных
                </h1>
                <p className="text-xs sm:text-lg">
                    Главная цель Ethicalnode.com при сборе персональных данных — предоставление Пользователям безопасных и эффективных услуг. Пользователи соглашаются с тем, что Ethicalnode.com также может использовать их персональные данные для:
                </p>
                <div className=' pl-2 sm:pl-5'>
                    <p className="text-xs sm:text-lg pt-2 sm:pt-4">
                        - предоставления услуг и клиентской поддержки по запросу Пользователей;
                    </p>
                    <p className="text-xs sm:text-lg pt-2 sm:pt-4">
                        - исполнения соглашений с Пользователями;
                    </p>
                    <p className="text-xs sm:text-lg pt-2 sm:pt-4">
                        - разрешения споров, сбора отзывов и выявления неисправностей;
                    </p>
                    <p className="text-xs sm:text-lg pt-2 sm:pt-4">
                        - индивидуальной настройки, анализа и совершенствования услуг, информационного наполнения и рекламы услуг;
                    </p>
                    <p className="text-xs sm:text-lg pt-2 sm:pt-4">
                        - информирования Пользователей об услугах, целевом маркетинге, обновлении услуг и рекламных предложениях на основании информационных предпочтений Пользователей;
                    </p>
                    <p className="text-xs sm:text-lg pt-2 sm:pt-4">
                        - сравнения персональных данных для подтверждения их точности и проверки их третьими сторонами в случаях, предусмотренных законодательством.
                    </p>
                </div>

            </div>

            <div className='mt-2 sm:mt-5'>
                <h1 className=" text-sm sm:text-xl font-bold text-center mb-2">
                    Маркетинг
                </h1>
                <p className="text-xs sm:text-lg">
                    Ethicalnode.com не продаёт и не предоставляет персональные данные Пользователей третьим лицам для маркетинговых целей, не предусмотренных Условиями использования, без прямого согласия Пользователей. Ethicalnode.com может объединять обезличенные данные с иной информацией, полученной от третьих лиц, и использовать их для совершенствования и персонификации услуг, информационного наполнения и рекламы.
                </p>

                <p className="text-xs sm:text-lg pt-2 sm:pt-4">
                    Ethicalnode.com может также предоставлять персональные данные Пользователей:
                </p>
                <p className="text-xs sm:text-lg pt-2 sm:pt-4">
                    провайдерам услуг, которые оказывают предусмотренное соглашениями содействие в предоставлении услуг (например, расследование мошенничеств, сбор платежей, обслуживание клиентов, услуги консультантов);
                </p>
                <p className="text-xs sm:text-lg pt-2 sm:pt-4">
                    третьим лицам, которым Пользователи прямо просят Ethicalnode.com направить свои персональные данные (или о которых Пользователи прямо уведомлены иным образом и соглашаются при использовании специальных услуг);
                </p>
                <p className="text-xs sm:text-lg pt-2 sm:pt-4">
                    правоохранительным органам, иным государственным органам или третьим лицам в ответ на запрос информации в рамках расследования уголовных дел, иной противоправной деятельности или любой деятельности, которая может повлечь привлечение к ответственности Ethicalnode.com или её Пользователей.
                </p>
                <p className="text-xs sm:text-lg pt-2 sm:pt-4">
                    Не ограничивая вышеизложенное, стараясь уважать личную информацию Пользователей, Ethicalnode.com не будет предоставлять персональные данные любым лицам, включая уполномоченные государственные органы, без запроса, оформленного в строгом соответствии с законом.
                </p>
            </div>

            <div className='mt-2 sm:mt-5'>
                <h1 className=" text-sm sm:text-xl font-bold text-center mb-2">
                    Куки-файлы
                </h1>
                <p className="text-xs sm:text-lg">
                    Ethicalnode.com может использовать куки-файлы (cookies) для помощи в анализе потока информации, индивидуальной настройки услуг, информационного наполнения и рекламы, а также для измерения эффективности сайтов и обеспечения надёжности и безопасности, с целью исследований рынка, отслеживания доходов, а также для улучшения функциональности веб-сайтов, мониторинга выполнения настоящих условий, охраны законных прав и интересов.
                </p>

                <p className="text-xs sm:text-lg pt-2 sm:pt-4">
                    Несмотря на то, что куки-файлы деперсонализированы, Пользователи всегда могут отказаться от их сохранения, поскольку эта функция определяется настройками браузера Пользователя.
                </p>

            </div>

            <div className='mt-2 sm:mt-5'>
                <h1 className=" text-sm sm:text-xl font-bold text-center mb-2">
                    Безопасность
                </h1>
                <p className="text-xs sm:text-lg">
                    Персональные данные Пользователей хранятся в информационных системах Ethicalnode.com и сохраняются различными способами (шифрование, пароли, ограничение доступа связанных компаний, сотрудников и подрядчиков, физическая безопасность и т.д.) для защиты персональных данных Пользователей от несанкционированного доступа и разглашения.
                </p>

                <p className="text-xs sm:text-lg pt-2 sm:pt-4">
                    Информационные системы веб-сайтов Ethicalnode.com, осуществляющие сбор, запись, систематизацию, накопление, хранение, уточнение, обновление, изменение и извлечение персональных данных Пользователей, размещаются в России; при первичной регистрации Пользователей и актуализации персональных данных трансграничная передача персональных данных не осуществляется, в исключительных случаях заменяется средствами удалённого доступа для неавтоматизированной обработки либо, если такая передача необходима в связи с законами соответствующих стран нахождения Пользователей, она осуществляется в соответствии с Конвенцией Совета Европы о защите физических лиц при автоматизированной обработке персональных данных.
                </p>

                <p className="text-xs sm:text-lg pt-2 sm:pt-4">
                    Использование, передача, распространение, предоставление, доступ, обезличивание, блокирование, удаление и уничтожение персональных данных Пользователей осуществляются только на основании решений уполномоченных представителей Ethicalnode.com в соответствии с требованиями применимого законодательства и законными интересами Пользователей.
                </p>
                <p className="text-xs sm:text-lg pt-2 sm:pt-4">
                    Ethicalnode.com не принимает решений, затрагивающих права и законные интересы Пользователей, на основании исключительно автоматизированной обработки персональных данных, за исключением случаев предоставления информации по результатам запроса, сделанного самим Пользователем с использованием интерфейса автоматизированных систем.
                </p>
                <p className="text-xs sm:text-lg pt-2 sm:pt-4">
                    При принятии юридически значимых решений, взаимодействии с Пользователями третьих лиц по просьбе Ethicalnode.com, во исполнение соглашений с Пользователями или по запросам Пользователей осуществляется неавтоматизированная обработка персональных данных в объёме, обусловленном целями такого взаимодействия, и с соблюдением требований о безопасности иных данных, не затрагиваемых обработкой.
                </p>
            </div>

            <div className='mt-2 sm:mt-5'>
                <h1 className=" text-sm sm:text-xl font-bold text-center mb-2">
                    Изменения
                </h1>
                <p className="text-xs sm:text-lg">
                    Ethicalnode.com постоянно совершенствует способы сбора, хранения и обработки данных, включая меры безопасности. В связи с этим, а также в случаях изменения законодательства о персональных данных Ethicalnode.com в любой момент может изменить настоящую Политику, разместив новую Политику на веб-сайтах Ethicalnode.com. Продолжение использования услуг Ethicalnode.com после внесения таких изменений подтверждает согласие Пользователя с такими изменениями, если получение отдельного согласия не требуется законом.
                </p>
            </div>

        </div>

    )
}

export default PrivacyPolicy
