import React from "react";
import { useTranslation } from "react-i18next";

const AccordionContent = ({ item, isActive, onClick, idx }) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        onClick={onClick}
        className="flex justify-between items-center sm:py-7 py-3 border-t cursor-pointer"
      >
        <p className="sm:text-lg text:sm font-bold">{t(`faqs_item_${idx}`)}</p>
        <span
          className={`transform ${
            isActive ? "-rotate-0" : "rotate-45"
          } sm:text-3xl text-xl text-greenTeal`}
        >
          &times;
        </span>
      </div>
      <div
        className={`relative overflow-hidden transition-all  ${
          isActive ? "h-auto transition-all" : "h-0"
        } `}
      >
        <div className="sm:px-5 px-2 sm:pb-5 pb-2 sm:text-lg text-sm">
          {t(`faqs_item_${idx}_content`)}
          {idx === 11 && (
            <>
              <p className=" mt-3">{t(`faqs_item_${idx}_content_${idx}`)}</p>
              <p className=" mt-3">{t(`faqs_item_${idx}_content_${idx}1`)}</p>
            </>
          )}
          {idx === 2 && (
            <>
              <p className=" mt-3">{t(`faqs_item_${idx}_content_${idx}`)}</p>
            </>
          )}
          {idx === 13 && (
            <>
              {/* <p className="mt-3">{t(`faqs_item_${index}_content`)}</p> */}
              {[1, 2, 3, 4, 5, 6, 7].map((_, index) => (
                <p className="mt-3" key={index}>
                  {t(`faqs_item_${idx}_content_${index + 1}`)}
                </p>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AccordionContent;
