import PasswordForm from "./PasswordForm";
import UserForm from "./UserForm";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

const UserProfile = ({ userID }) => {
  const { t } = useTranslation();
  const { token } = localStorage;

  return (
    <>
      {token ? (
        <div className="sm:w-3/6 md:w-2/6 mx-auto text-center pt-5 pb-12">
          <h1 className="text-blue text-2xl md:text-3xl xl:text-4xl font-bold pb-6 md:pb-10">{t("profile")}</h1>
          <UserForm userID={userID} />
          <PasswordForm />
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default UserProfile;
