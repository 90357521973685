import React from "react";
import Peer from "../../static/landing-images/Peer.png";
import ButtonLink from "./LandingButton/ButtonLink";
import { useTranslation } from "react-i18next";

const LandingPeer = () => {
  const { t } = useTranslation();
  return (
    <div className="flex sm:flex-row flex-col items-start justify-between sm:mt-36 mt-10">
      <div className="">
        <img className="object-cover w-full h-full" src={Peer} alt="" />
      </div>
      <div className="sm:w-5/12 w-full sm:mt-0 mt-4">
        <h3 className="sm:text-4xl text-2xl font-bold sm:text-left text-center">
          {t("landing_only__peer")}
        </h3>
        <p className="sm:mt-7 mt-3 sm:text-lg text-sm sm:text-left text-center">
          {t("landing_only__peer_body")}
        </p>
        <div className="sm:mt-8 mt-5">
          <ButtonLink link="/login">{t("start_investing")}</ButtonLink>
        </div>
      </div>
    </div>
  );
};

export default LandingPeer;
