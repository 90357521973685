import React, { useState } from "react";
import { accordionItems } from "../../../static/consts";
import AccordionContent from "./AccordionContent";

const Accordion = () => {
  const [active, setActive] = useState();

  const handleClick = (name) => {
    setActive(name === active ? null : name);
  };

  return (
    <div className="sm:mt-10 mt-4">
      <h1 className="sm:text-4xl text-2xl text-center font-bold">FAQS</h1>

      <div className=" sm:mt-14 mt-5">
        {accordionItems.map((item, index) => {
          let isActive = active === item.name;
          return (
            <AccordionContent
              key={index}
              item={item}
              onClick={() => handleClick(item.name)}
              isActive={isActive}
              idx={index + 1}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Accordion;
