import React from "react";
import ButtonLink from "./LandingButton/ButtonLink";
import { useTranslation } from "react-i18next";

import { Bitcoin, BNB, USDT, ETH } from "../../static/landing-images";
const LandingValute = () => {
  const { t } = useTranslation();

  return (
    <div className="flex sm:items-start items-center sm:justify-between sm:flex-row flex-col sm:mt-20 mt-10">
      <div className="sm:w-9/12 w-full">
        <h3 className="sm:text-3xl text-2xl font-bold">
          {t("everything_for_you")}
        </h3>
        <p className="sm:my-6 my-3 sm:text-lg text-sm">
          {t("everything_for_you__body")}
        </p>
        <div>
          <ButtonLink link="/login">{t("start_investing")}</ButtonLink>
        </div>
      </div>
      <div className="flex flex-wrap sm:justify-end justify-center ml-4 sm:mt-0 mt-5 ">
        <img src={ETH} alt="ETH" />
        <img
          src={USDT}
          alt="USDT"
          className="transform sm:-translate-y-10 translate-y-0"
        />
        <img src={Bitcoin} alt="Bitcoin" />
        <img
          src={BNB}
          alt="BNB"
          className="transform sm:-translate-y-10 translate-y-0"
        />
      </div>
    </div>
  );
};

export default LandingValute;
