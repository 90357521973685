import logo from "../../static/images/ethicalnode.svg";
import avatar from "../../static/images/avatar.svg";
import LanguageSelect from "../LanguageSelect/LanguageSelect";
import UserDropdown from "../UserDropdown/UserDropdown";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useState } from "react";
import Navbar from "../Navbar/Navbar";
import Modal from "../Modals/Modal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import IconSog from "../../static/images/соглаш.png";
import AgreementsModal from "../Modals/AgreementsModal/AgreementsModal";
import {Link} from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { firstName } = localStorage;

  const [navBtn, setNavBtn] = useState(false);
  const menuOpen = Boolean(navBtn);
  const handleClickMenu = (event) => {
    event.preventDefault();
    setNavBtn(!navBtn);
  };
  const handleCloseMenu = () => {
    setNavBtn(null);
  };

  const [modalOpen, setModalOpen] = useState({
    open: false,
    view: "",
  });

  const handleOpenModal = (viewStr) => {
    handleCloseMenu();
    setModalOpen({
      open: true,
      view: viewStr,
    });
  };
  const closeModal = () =>
    setModalOpen({
      open: false,
      view: "",
    });

  return (
    <div className="bg-white mb-14 shadow fixed w-full z-10">
      <div className="w-10/12 flex justify-between mx-auto h-20">
        <div className="2xl:w-2/5 xl:w-2/5 lg:w-5/12 flex justify-between self-center ">
          <a href="/">
            <img src={logo} alt="logo" className="w-24 mr-8" />
          </a>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <div className="inline-block 2xl:mr-8 lg:mr-2">
              <LanguageSelect />
            </div>
            <p className="inline-block w-44">
              <span className="text-gray font-medium 2xl:text-base lg:text-xs mr-2 hidden">
                {t("coin_rate")}
              </span>
              <span className="text-blue font-bold 2xl:text-base lg:text-sm hidden">
                1,01/$
              </span>
            </p>
          </Box>
        </div>
        {/* 2xl:w-2/5 xl:w-2/5 lg:w-5/12  */}
        <Box
          className="self-center justify-between"
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          {/* <Button
            variant="contained"
            onClick={handleOpenModal}
            style={{
              borderRadius: 8,
              height: 50,
              width: "35%",
              backgroundColor: "#191D43",
              fontSize: "13px",
              textTransform: "none",
            }}
          >
            {t("withdraw_funds")}
          </Button> */}
          <div className="flex items-center mr-2 cursor-pointer gap-4">
            <span
              className=" text-lg"
              onClick={() => handleOpenModal("instruction")}
            >
              Инструкция
            </span>
            <img
              className=" w-6"
              onClick={() => handleOpenModal("agremnents")}
              src={IconSog}
              alt=""
            />
          </div>

          <Modal show={modalOpen.open} onClose={closeModal}>
            <AgreementsModal view={modalOpen.view} />
          </Modal>

          <div className="ml-3 self-center">
            <img
              src={avatar}
              alt="avatar"
              className="inline mr-2 2xl:w-12 lg:w-8"
            />
            <span className="text-gray font-medium pr-2 2xl:text-lg lg:text-sm">
              {t("hi")},
            </span>
            <UserDropdown name={firstName ? firstName : "UserName"} />
          </div>
        </Box>
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            position: "absolute",
            top: 28,
            right: 30,
          }}
        >
          <div className="flex items-center mr-6 cursor-pointer gap-4">
            <span
              className=" text-base sm:text-lg"
              onClick={() => handleOpenModal("instruction")}
            >
              Инструкция
            </span>
            <img
              className=" w-6"
              onClick={() => handleOpenModal("agremnents")}
              src={IconSog}
              alt=""
            />
          </div>
          <Button
            id="basic-button"
            aria-controls={menuOpen ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? "true" : undefined}
            onClick={handleClickMenu}
            style={{
              padding: 0,
              minWidth: 38,
            }}
          >
            <div className="space-y-2 block">
              <div
                className="w-8 h-1 bg-gray-600 transition transform duration-300"
                style={
                  menuOpen
                    ? { transform: "translate(0, 12px) rotate(-45deg)" }
                    : {}
                }
              ></div>
              <div
                className="w-8 h-1 bg-gray-600 transition transform duration-300"
                style={menuOpen ? { transform: "scale(0)" } : {}}
              ></div>
              <div
                className="w-8 h-1 bg-gray-600 transition transform duration-300"
                style={
                  menuOpen
                    ? { transform: "translate(0, -12px) rotate(45deg)" }
                    : {}
                }
              ></div>
            </div>
          </Button>
        </Box>
      </div>

      <Navbar
        id="basic-menu"
        navBtn={navBtn}
        show={menuOpen}
        onClose={handleCloseMenu}
      >
        <div className="bg-white mt-8 mb-8 text-center">
          <span className="text-gray font-medium pr-2 2xl:text-lg lg:text-sm">
            {t("hi")},
          </span>
          <UserDropdown
            name={firstName ? firstName : "UserName"}
            showSingleText={true}
          />
        </div>

        <div className="py-3 text-center text-blue text-xl font-bold">
          <Link to="/profile" onClick={handleCloseMenu}>{t("profile")}</Link>
        </div>

        {/* <div className="py-3 w-11/12 mx-auto">
          <Button
            variant="contained"
            onClick={handleOpenModal}
            style={{
              borderRadius: 8,
              height: 50,
              width: "100%",
              backgroundColor: "#191D43",
              fontSize: "13px",
              fontWeight: 'bold',
              textTransform: "none",
            }}
          >
            {t("withdraw_funds")}
          </Button>
        </div> */}

        <div className="py-3 text-center text-blue text-xl font-bold cursor-pointer">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();

              localStorage.removeItem("id");
              localStorage.removeItem("firstName");
              localStorage.removeItem("token");
              localStorage.removeItem("email");
              

              navigate("/login");
            }}
          >
            {t("logout")}
          </a>
        </div>

        <div className="fixed xsm:static text-center bottom-10 left-0 w-full">
          <LanguageSelect
            anchorPosLeft={window.innerWidth * 0.5 + 26}
            anchorPosTop={window.innerHeight - 190}
          />
        </div>
      </Navbar>

      {/* <Modal show={modalOpen} onClose={closeModal}>
        <WithdrawForm onClick={closeModal}  />
      </Modal> */}
    </div>
  );
};

export default Header;
