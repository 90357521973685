import SyncLoader from "react-spinners/SyncLoader";

const DashboardItem = ({
  value,
  text,
  classAlign,
  isMobile,
  customEndText = "USD",
}) => {
  return (
    <div className={classAlign}>
      {
        <p className="text-blue font-bold text-lg md:text-xl">
          {value === undefined ? (
            <SyncLoader color="#4BCBC8" loading={value} size={15} />
          ) : (
            `${value} ${customEndText}`
          )}
        </p>
      }
      <p
        className={
          isMobile
            ? "text-xs w-28 text-gray font-medium"
            : "text-xs text-gray font-medium"
        }
      >
        {text}
      </p>
    </div>
  );
};

export default DashboardItem;
