import DashboardItem from "../DashboardItem/DashboardItem";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import CallService from "../../services/CallService";
import Carousel from "react-simply-carousel";
import moment from "moment";
import WithdrawForm from "../Modals/WithdrawForm";
import Modal from "../Modals/Modal";

// Radio imports
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
// import FormLabel from '@mui/material/FormLabel';
// Radion imports end

// Table imports
import Table from "@mui/material/Table";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// table inports end

import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";

const InvestmetsContent = () => {
  const [dashboardValues, setDashboardValues] = useState(null);
  const [tableValues, setTableValues] = useState([]);

  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const { t } = useTranslation();
  const { firstName, token } = localStorage;
  const [activeSlide, setActiveSlide] = useState(0);

  const [typeView, setTypeView] = useState("all");
  const [hideDisplayed, setHideDisplayed] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalIsId, setModalIsId] = useState({
    id: null,
    amount: null,
  });
  const handleOpenModal = (id, amount) => (event) => {
    setModalIsId({
      id,
      amount,
    });
    setModalOpen(true);
  };
  const closeModal = async () => {
    setModalOpen(false);
    setTimeout(async () => {
      await loadTable();
    }, 2500);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const sortingData = (dataTable, orderV, orderByV) => {
    if (orderV && orderByV) {
      const comparator = getComparator(orderV, orderByV);
      dataTable && setTableValues(stableSort(dataTable, comparator));
    } else dataTable && setTableValues(dataTable);
  };

  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    const orderV = isAsc ? "desc" : "asc";
    setOrder(orderV);
    setOrderBy(property);
    sortingData(tableValues, orderV, property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangeTypeView = async (event) => {
    setTypeView(event.target.value);
    await loadTable({
      view: event.target.value,
    });
  };

  const handleChangeHideDisplayed = async (event) => {
    setHideDisplayed(event.target.checked);
    await loadTable({
      hideDisplayed: event.target.checked,
    });
  };
  useEffect(() => {
    (async () => {
      const data = await CallService.GetDashboardInvestedData();
      data && setDashboardValues(data);
      await loadTable();
    })();
  }, []);

  const loadTable = async (data = {}) => {
    const dataTable = await CallService.GetTableInvestedData({
      view: typeView,
      hideDisplayed,
      ...data,
    });
    dataTable && sortingData(dataTable, order, orderBy);
  };

  const handleEventButton = async (event) => {
    await CallService.SetDateTransaction({
      id: event.target.name,
      type: event.target.getAttribute("data-type"),
    }).then(async () => {
      setTimeout(async () => {
        await loadTable();
      }, 1000);
    });
  };

  const schemaButtonTable = {
    withdrawDisabled: t("withdraw"),
    withdraw: t("withdraw"),
    unstake: t("unstake"),
  };

  const renderButton = (type, id, amount) => {
    if (type === "none") return null;
    if (type === "withdraw")
      return (
        <Button
          onClick={handleOpenModal(id, amount)}
          className={{ type }}
          data-type={type}
          name={id}
          disabled={type === "withdrawDisabled"}
          variant="text"
        >
          {schemaButtonTable[type]}
        </Button>
      );
    return (
      <Button
        onClick={handleEventButton}
        className={{ type }}
        data-type={type}
        name={id}
        disabled={type === "withdrawDisabled"}
        variant="text"
      >
        {schemaButtonTable[type]}
      </Button>
    );
  };

  const headCells = [
    {
      id: "id",
      label: t("id_invested"),
    },
    {
      id: "date",
      label: t("date"),
    },
    {
      id: "invested",
      label: t("invested_table"),
    },
    {
      id: "available_for_withdrawal",
      label: t("available_for_withdrawal"),
    },
    {
      id: "financial_results",
      label: t("financial_results"),
    },
    {
      id: "percent_change",
      label: t("percent_change"),
    },
    {
      id: "days_until_unlock",
      label: t("days_until_unlock"),
    },
    {
      id: "hash",
      label: t("hash"),
    },
  ];

  return (
    <>
      {firstName && token ? (
        <>
          <Box className="mb-5" sx={{ display: { xs: "block", sm: "none" } }}>
            <Carousel
              updateOnItemClick
              containerProps={{
                style: {
                  display: "block",
                  width: "100%",
                  justifyContent: "space-between",
                },
              }}
              infinite={false}
              activeSlideIndex={activeSlide}
              activeSlideProps={{
                style: {
                  background: "blue",
                },
              }}
              onRequestChange={setActiveSlide}
              speed={500}
              delay={2000}
            >
              <DashboardItem
                value={dashboardValues?.invested.toLocaleString()}
                text={t("invested")}
                classAlign="pl-12 text-right"
              />
              <DashboardItem
                value={dashboardValues?.common_capital.toLocaleString()}
                text={t("common_capital")}
                classAlign="pl-12 text-right"
              />
              <DashboardItem
                value={dashboardValues?.percent_change.toLocaleString()}
                text={t("percent_change")}
                customEndText="%"
                classAlign="pl-12 text-right"
              />
              <DashboardItem
                value={dashboardValues?.available_for_withdrawal.toLocaleString()}
                text={t("available_for_withdrawal")}
                classAlign="pl-12 text-right"
              />
            </Carousel>
          </Box>
          <Box
            style={{
              justifyContent: "space-between",
            }}
            className="flex mb-10 mr-12"
            sx={{ display: { xs: "none", sm: "flex" } }}
          >
            <div className="text-investments">{t("investments")}</div>
            <div>
              <div className="flex">
                <DashboardItem
                  value={dashboardValues?.invested.toLocaleString()}
                  text={t("invested")}
                  classAlign="pl-12 text-right"
                />
                <DashboardItem
                  value={dashboardValues?.common_capital.toLocaleString()}
                  text={t("common_capital")}
                  classAlign="pl-12 text-right"
                />
                <DashboardItem
                  value={dashboardValues?.percent_change.toLocaleString()}
                  text={t("percent_change")}
                  customEndText="%"
                  classAlign="pl-12 text-right"
                />
                <DashboardItem
                  value={dashboardValues?.available_for_withdrawal.toLocaleString()}
                  text={t("available_for_withdrawal")}
                  classAlign="pl-12 text-right"
                />
              </div>
            </div>
          </Box>
          <div
            style={{
              justifyContent: "space-between",
            }}
            className="flex justify-left flex-nowrap"
          >
            <div className="flex justify-left flex-nowrap">
              <FormControl>
                <FormControlLabel
                  checked={hideDisplayed}
                  onChange={handleChangeHideDisplayed}
                  control={<Switch />}
                  label={t("hide_displayed")}
                />
              </FormControl>
            </div>
            <div className="flex justify-right flex-nowrap">
              <FormControl>
                <RadioGroup
                  row
                  value={typeView}
                  onChange={handleChangeTypeView}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="all"
                    control={<Radio defaultChecked />}
                    label={t("all")}
                  />
                  <FormControlLabel
                    value="only_available_withdrawal"
                    control={<Radio />}
                    label={t("only_available_withdrawal")}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div className="flex justify-center flex-wrap">
            <TableContainer
              style={{
                borderRadius: "10px",
                fontFamily: "Roboto",
                padding: "18px",
              }}
              className={"table-invested"}
              component={Paper}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell align="center">
                        <TableSortLabel
                          className={
                            orderBy === headCell.id ? "table-active" : null
                          }
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : "asc"}
                          onClick={createSortHandler(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell align="center">{t("action")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableValues.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center" component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell align="center">
                        {moment(row.date).format("DD/MM/YY")}
                      </TableCell>
                      <TableCell align="center">{row.invested}</TableCell>
                      <TableCell align="center">
                        {row.available_for_withdrawal}
                      </TableCell>
                      <TableCell align="center">
                        {row.financial_results}
                      </TableCell>
                      <TableCell align="center">
                        {row.percent_change}%
                      </TableCell>
                      <TableCell align="center">
                        {row.days_until_unlock}
                      </TableCell>
                      <TableCell align="center">{row.hash}</TableCell>
                      <TableCell align="center">
                        {renderButton(row.button, row.id, row.amount_3)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Modal show={modalOpen} onClose={closeModal}>
              <WithdrawForm data={modalIsId} onClick={closeModal} />
            </Modal>
          </div>
        </>
      ) : (
        <Navigate to="/profile" />
      )}
    </>
  );
};

export default InvestmetsContent;
