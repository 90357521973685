import React from "react";
import Market from "../../static/landing-images/Market.png";
import islamIcon from "../../static/landing-images/islamIcon.svg";
import { marketHome } from "../../static/consts";
import { useTranslation } from "react-i18next";

const LandingMarket = () => {
  const { t } = useTranslation();

  return (
    <div className="sm:mt-20 mt-10 ">
      <div className="flex flex-col items-center">
        <h1 className="sm:text-4xl text-2xl font-bold">
          {t("landing_expext")}
        </h1>
        <p className="sm:mt-4 mt-3 sm:text-lg text-sm">
          {t("landing_expext_sub")}
        </p>
      </div>
      <div className="flex flex-col-reverse sm:flex-row items-center justify-between sm:mt-16 mt-5 relative">
        <div className="">
          <img className="w-full relative z-30" src={Market} alt="" />
        </div>
        <div className="sm:ml-20 ml-0 sm:w-6/12 w-full ">
          {marketHome.map((item, index) => (
            <div key={index} className="inline mt-4 first:mt-0">
              <span className="flex sm:w-3 sm:h-3 w-2.5 h-2.5 bg-greenTeal rounded-full"></span>
              <p className="sm:text-lg text-sm font-bold transform -translate-y-5 sm:ml-7 ml-4 break-all">
                {t(`landing_expext_body_${index}`)}
              </p>
            </div>
          ))}
        </div>
        <img
          src={islamIcon}
          className="sm:absolute sm:block hidden left-96 -bottom-64 transform -rotate-45 z-1"
        />
      </div>
    </div>
  );
};

export default LandingMarket;
