import success from "../../static/images/success.svg";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const SuccessModal = ({ onClick, text }) => {
  const { t } = useTranslation();

  return (
    <div className="text-center sm:w-96 h-72">
      <div className="w-10/12 mx-auto">
        <img src={success} alt="success" className="block mx-auto pt-8 pb-4" />
        <div>
          <h2 className="text-blue font-bold text-xl pb-4">{t("success")}</h2>
          <p className="text-blue font-normal text-sm pb-4">{text}</p>
        </div>
        <Button
          variant="contained"
          onClick={onClick}
          style={{
            borderRadius: 8,
            height: 50,
            width: "75%",
            backgroundColor: "#EDEDF4",
            fontSize: "16px",
            textTransform: "none",
            color: "#191D43",
            fontWeight: "600",
          }}
        >
          {t("okay")}
        </Button>
      </div>
    </div>
  );
};

export default SuccessModal;
