import React from "react";
import "./button-link.scss";
import { Link } from "react-router-dom";
import Arrow from "../../../static/landing-images/arrow.svg";

const ButtonLink = ({ children, link, onClick }) => {
  return (
    <div className="landing__button flex justify-center sm:justify-start">
      {link ? (
        <Link
          className="landing__button__link sm:w-auto w-full flex justify-center"
          to={link}
          state="/landing"
        >
          <span className="text-lg">{children}</span>
          <div>
            <img src={Arrow} alt="" />
          </div>
        </Link>
      ) : (
        <div className="landing__button__link" onClick={onClick}>
          {children}
          <div>
            <img src={Arrow} alt="" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ButtonLink;
