import {useFormik} from "formik";
import {Button, TextareaAutosize} from "@mui/material";
import {CssTextField} from "../../static/consts";
import SuccessModal from "../Modals/SuccessModal";
import React, {useState, useEffect} from "react";
import Modal from "../Modals/Modal";
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CallService from "../../services/CallService";
import {useNavigate} from "react-router-dom";

const SupportGuestForm = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
        formik.setFieldValue('email', '');
        formik.setFieldTouched('email', false);
        formik.setFieldValue('text', '');
        formik.setFieldTouched('text', false);
    };

    const {t} = useTranslation();
    const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


    const validationSchema = yup.object({
        email: yup
            .string("Enter your email")
            .email(t("valid_email"))
            .matches(emailPattern, "Email is not valid")
            .required(t("email_required")),
        text: yup
            .string(t("description"))
            .required(t('required_field')),
    });

    const formik = useFormik({
        initialValues: {
            email: "",
            text: "",
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await CallService
                .SendSupportGuest(values)
                .then(() => setIsModalOpen(true))
        },
    });

    return (
        <div
            className="bg-white rounded-xl font-bold text-blue shadow mx-auto"
            style={{
                padding: '2rem',
                paddingTop: 'none',
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <CssTextField
                    fullWidth
                    id="email"
                    name="email"
                    placeholder={t("enter_email")}
                    style={{
                        marginBottom: 12,
                        fontSize: "16px",
                        color: "#191D43",
                        width: "100%",
                    }}
                    inputProps={{
                        style: {
                            padding: 10,
                        },
                    }}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />
                <TextareaAutosize
                    id="support_text"
                    name="text"
                    placeholder={t("description")}
                    type="text"
                    style={{
                        borderRadius: '10px',
                        border: '2px solid #ededf4',
                        width: '100%',
                        padding: '10px',
                        boxSizing: 'border-box',
                    }}
                    value={formik.values.text}
                    onChange={formik.handleChange}
                    minRows={5}
                />

                <p
                    className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-1d1r5q-MuiFormHelperText-root"
                    id="phone-helper-text"
                    style={{
                        display: formik.errors.text ? 'inline-block' : 'none',
                        color: 'red',
                        fontSize: '.75rem',
                        fontWeight: 400,
                        float: 'left'
                    }}
                >
                    {formik.errors.text}
                </p>

                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className="btn-gradient btn-opacity"
                    style={{
                        borderRadius: 8,
                        height: 50,
                        width: "100%",
                        backgroundColor: "#4BCBC8",
                        fontSize: "16px",
                        textTransform: "none",
                        marginTop: '1rem'
                    }}
                >
                    {t("send")}
                </Button>
            </form>
            <Modal show={isModalOpen} onClose={closeModal}>
                <SuccessModal onClick={closeModal} text={t("success_send_support")}/>
            </Modal>
        </div>
    );
};

export default SupportGuestForm;
