import React from "react";
import { useTranslation } from "react-i18next";

const LandingWorking = () => {
  const { t } = useTranslation();
  return (
    <div className="sm:mt-32 mt-10 sm:p-12 p-6 mb-6 flex justify-center bg-white shadow-shadow200">
      <div className="flex flex-col items-center text-textColor">
        <h1 className="sm:text-3xl text-2xl font-bold">
          {t("how_we_operate")}
        </h1>
        <p className="max-w-[874px] w-full sm:my-6 my-3 sm:text-2xl text-sm text-center">
          {t("how_we_operate_body")}
        </p>
      </div>
    </div>
  );
};

export default LandingWorking;
