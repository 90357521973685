import { team } from "../../static/consts";
import AboutUsCard from "../AboutUs/AboutUsCard";

const TeamModal = () => {
  return (
    <div>
      <div className="h-700 grid grid-cols-1 sm:grid-cols-5 rounded-xl px-4 md:px-6 xl:px-8 bg-main overflow-auto h-modal-responsive">
        {team.map((item, i) => (
          <AboutUsCard key={i} {...item} />
        ))}
      </div>
    </div>
  );
};

export default TeamModal;
