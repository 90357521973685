import { useTranslation } from "react-i18next";
import IconSupport from "../../static/images/headset-solid.svg";
import {Link} from "react-router-dom";

const Support = ({ top, right }) => {
  const { t } = useTranslation();

  return (
      <Link to="/support" style={{
          position: 'absolute',
          top: top,
          right: right,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
      }}>
          <img src={IconSupport} alt="" style={{
              width: '1.5rem',
          }} />
          <p style={{
              marginLeft: '.75rem',
              fontWeight: 'bold',
              lineHeight: '1rem'
          }}>{t("support")}<br />Ethicalnode</p>
      </Link>
  );
};

export default Support;
