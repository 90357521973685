import InvestCard from "../InvestCard/InvestCard";
import DashboardItem from "../DashboardItem/DashboardItem";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { invests } from "../../static/consts";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import CallService from "../../services/CallService";
import Carousel from "react-simply-carousel";
import InvestSelectModal from "../Modals/InvestSelectModal";
import Modal from "../Modals/Modal";

const MainContent = () => {
  const [dashboardValues, setDashboardValues] = useState(null);
  const { t } = useTranslation();
  const { firstName, token } = localStorage;
  const [activeSlide, setActiveSlide] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const data = await CallService.GetDashboardData();
      data && setDashboardValues(data);
    })();
  }, []);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);


  const handleTelegramClick = () => {
    const telegramUsername = "ayratdavlet";
    const message = "Assalamu Alaikum, I would like to request a withdrawal.";  // Predefined message
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  
    if (isMobile) {
      // Use Telegram deep link for mobile devices, with message
      window.location.href = `tg://msg?text=${encodeURIComponent(message)}&to=${telegramUsername}`;
    } else {
      // Open Telegram in web version with message for non-mobile devices
      window.open(`https://t.me/${telegramUsername}?text=${encodeURIComponent(message)}`, "_blank");
    }
  };
  

  return (
    <>
      {firstName && token ? (
        <>
          <Box className="mb-5" sx={{ display: { xs: "block", sm: "none" } }}>
            <Carousel
              updateOnItemClick
              containerProps={{
                style: {
                  display: "block",
                  width: "100%",
                  justifyContent: "space-between",
                },
              }}
              infinite={false}
              activeSlideIndex={activeSlide}
              activeSlideProps={{
                style: {
                  background: "blue",
                },
              }}
              onRequestChange={setActiveSlide}
              speed={500}
              delay={2000}
            >
              <DashboardItem
                value={dashboardValues?.deposits.toLocaleString()}
                text={t("you_invested")}
                classAlign="mr-3 pl-4 py-2 bg-white rounded-lg text-left cursor-pointer"
                isMobile={true}
              />
              <DashboardItem
                value={dashboardValues?.profit.toLocaleString()}
                text={t("profit")}
                classAlign="mr-3 pl-4 py-2 bg-white rounded-lg text-left cursor-pointer"
                isMobile={true}
              />
              <DashboardItem
                customEndText="%"
                value={dashboardValues?.percent_change.toLocaleString()}
                text={t("percent_change")}
                classAlign="mr-3 pl-4 py-2 bg-white rounded-lg text-left cursor-pointer"
                isMobile={true}
              />
            </Carousel>
          </Box>
          <Box
            className="flex justify-end mb-10 mr-12 flex-col items-end"
            sx={{ display: { xs: "none", sm: "flex" } }}
          >
            <div className="flex">
              <DashboardItem
                value={dashboardValues?.deposits.toLocaleString()}
                text={t("you_invested")}
                classAlign="pl-12 text-right"
              />
              <DashboardItem
                value={dashboardValues?.profit.toLocaleString()}
                text={t("profit")}
                classAlign="pl-12 text-right"
              />
              <DashboardItem
                customEndText="%"
                value={dashboardValues?.percent_change.toLocaleString()}
                text={t("percent_change")}
                classAlign="pl-12 text-right"
              />
            </div>
            <div className="bg-blue-100 p-4 rounded-lg text-center border border-gray-300 max-w-md ml-auto mt-6">
      <p className="text-gray-800 text-lg">
      {t("telegram_contact")}
        <span
          className="text-blue-600 cursor-pointer underline ml-1 hover:text-blue-300"
          onClick={handleTelegramClick}
        >
          Telegram
        </span>
      </p>
    </div>
          </Box>
          <div className="flex justify-center">
            <div className="flex flex-col items-start">
              <button
                onClick={openModal}
                className="ml-8 mb-2 md:w-80 shadow-shadow200 bg-white px-3 text-blue rounded text-center outline-none cursor-pointer h-12 font-bold flex items-center"
              >
                {t("invest_modal_transfer_title")}
                <span
                  style={{
                    transform: "rotate(180deg)",
                    display: "inline-block",
                    marginLeft: 6,
                  }}
                >
                  &#8963;
                </span>
              </button>
              <Modal show={isOpen} onClose={closeModal}>
                <InvestSelectModal />
              </Modal>

              <div className="flex justify-center flex-wrap">
                {invests.map((item) => (
                  <div className="w-96" key={item.name}>
                    <InvestCard
                      item={item}
                      name={item.name}
                      image={item.image}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Navigate to="/profile" />
      )}
    </>
  );
};

export default MainContent;
