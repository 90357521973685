import { useState } from "react";
import { useTranslation } from "react-i18next";
import SendCodeSignIn from "./SendCodeSignIn";
import AuthenticateSignInForm from "./AuthenticateSignInForm";

const SignInForm = () => {
  const { t } = useTranslation();
  const [data, setData] = useState(null)

  const authenticateCode = (data) => setData(data)

  const cancelAuthenticate = () => setData(null)

  return (
    <div className="w-4/6 mx-auto mb-8">
      <h1 className="text-5xl mt-8 font-bold text-green h-24 lg:h-44">
        {t("sign_in")}
      </h1>

      <SendCodeSignIn sendCodeCallback={authenticateCode} />
      {/* { data == null
          ? <SendCodeSignIn sendCodeCallback={authenticateCode} />
          : <AuthenticateSignInForm cancelAuthenticateCallback={cancelAuthenticate} email={data.email} password={data.password} />
      } */}
    </div>
  );
};

export default SignInForm;
