import React from "react";
import { Box } from "@mui/material";
import Instruction from "./Instruction";
import Сonvention from "./Сonvention";

const AgreementsModal = ({ view }) => {
  return (
    <Box
      sx={{
        height: { xs: "500px", md: "600px", lg: "700px" },
        maxWidth: "1000px",
        width: "100%",
        overflowY: "auto",
        paddingBottom: "30px",
      }}
    >
      <div className="mt-9 px-5">
        {view === "instruction" && <Instruction />}
        {view === "agremnents" && <Сonvention />}
      </div>
    </Box>
  );
};

export default AgreementsModal;
