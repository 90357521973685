import Button from "@mui/material/Button";
import { useFormik } from "formik";
import { useState } from "react";
import Modal from "../Modals/Modal";
import SuccessModal from "../Modals/SuccessModal";
import * as yup from "yup";
import { CssTextField, PasteField } from "../../static/consts";
import { useTranslation } from "react-i18next";
import CallService from "../../services/CallService";

const InvestCardForm = ({ name }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { t } = useTranslation();

  const closeModal = () => {
    setIsOpen(false);
    formik.resetForm();
  };

  const validationSchema = yup.object({
    amount: yup.number("Enter your value").required("Value is required").min(100, 'not less than 100$'),
    hash: yup.string("Paste your hash").required(t('Hash is required')).length(66, t('hash length is 64 chars')),
  });
  const disabled = name === 'Marhaba' || name === 'Staking'
  const formik = useFormik({
    initialValues: {
      productType: name,
      amount: "",
      hash: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsDisabled(true);
      const data = await CallService.InvestCash(values);
      if (data) {
        setIsOpen(true);
        setIsDisabled(false);
        resetForm();
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <CssTextField
          fullWidth
          id="amount"
          name="amount"
          type="number"
          disabled={disabled}
          placeholder={t("value")}
          inputProps={{
            style: {
              padding: 10,
              cursor: disabled ? "not-allowed" : "pointer" 
            },
            inputProps: { min: 100}
          }}
          value={formik.values.amount}
          onChange={formik.handleChange}
          error={formik.touched.amount && Boolean(formik.errors.amount) }
          helperText={formik.touched.amount && formik.errors.amount && t('not less than 100$')}
        />
        <div className="relative mb-4 mt-2">
          <PasteField
            fullWidth
            id="hash"
            disabled={disabled}
            name="hash"
            placeholder={t("transaction_hash")}
            inputProps={{
              style: {
                padding: 10,
                width: "60%",
                cursor: disabled ? "not-allowed" : "pointer" 
              },
            }}
            value={formik.values.hash}
            onChange={formik.handleChange}
            error={formik.touched.hash && Boolean(formik.errors.hash)}
            helperText={formik.touched.hash && formik.errors.hash}
          />
          <Button
            variant="contained"
            disabled={disabled}
            onClick={() => {
              navigator.clipboard
                .readText()
                .then((clipText) => formik.setFieldValue("hash", clipText));
            }}
            style={{
              position: "absolute",
              zIndex: 2,
              top: 1,
              right: 2,
              height: 38,
              width: "30%",
              color: "#191D43",
              backgroundColor: "#EDEDF4",
              boxShadow: "none",
              textTransform: "none",
              fontWeight: "bold",
              borderRadius: "0px 8px 8px 0px",
              cursor: disabled ? "not-allowed" : "pointer" 
            }}
          >
            {t("paste")}
          </Button>
        </div>
        <Button
          variant="contained"
          type="submit"
          value={name}
          disabled={disabled === true ? disabled : isDisabled}
          className="btn-gradient"
          style={{
            borderRadius: 8,
            height: 50,
            width: "100%",
            backgroundColor: "#4BCBC8",
            fontSize: "16px",
            textTransform: "none",
            cursor: disabled ? "not-allowed" : "pointer" 
          }}
        >
          {t("invest")}
        </Button>
      </form>
      <Modal show={isOpen} onClose={closeModal}>
        <SuccessModal onClick={closeModal} text={t("success_invest")} />
      </Modal>
    </>
  );
};

export default InvestCardForm;
