import LanguageSelect from "../LanguageSelect/LanguageSelect";
import LoginSlider from "../LoginSlider/LoginSlider";
import {Navigate} from "react-router-dom";
import logo from "../../static/images/ethicalnode.svg";
import Modal from "../Modals/Modal";
import TeamModal from "../Modals/TeamModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SupportIcon from "../Support/SupportIcon";

const LoginPage = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { token } = localStorage;
  const { t } = useTranslation();

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      {!token ? (
        <div className="flex flex-col lg:min-h-screen lg:flex-row">
          <div className="lg:w-1/2 bg-main">
            <div className="lg:h-56 lg:flex lg:justify-center">
              <img
                src={logo}
                alt="logo"
                className="inline-block self-center lg:w-40 w-28 lg:ml-0 lg:mt-0 mt-4 ml-8 "
              />
            </div>
            <div className="pt-5">
              <LoginSlider />
            </div>
          </div>
          <div className="lg:w-1/2">
            <div className="lg:w-11/12 lg:h-5/6 lg:flex lg:flex-col lg:justify-between">
              <div>
                <div className="lg:h-1/5 lg:flex lg:justify-end lg:items-center">
                  <button
                    className="hover:text-green font-bold mr-6 lg:block hidden"
                    onClick={openModal}
                  >
                    {t("our_team")}
                  </button>
                  <div className="absolute top-5 right-10 lg:static">
                    <LanguageSelect />
                  </div>
                </div>
                <SupportIcon top="5.5rem" right="2rem" />
                <div>{children}</div>
              </div>
              <div className="text-center lg:text-left lg:w-4/6 lg:mx-auto">
                <p className="text-xs lg:text-base lg:text-gray lg:inline-block lg:w-72 lg:mt-20 mb-8 text-gray">
                  ©2022 All Rights Reserved. Ethicalnode
                </p>
              </div>
            </div>
          </div>
          <Modal show={isOpen} onClose={closeModal}>
            <TeamModal onClick={closeModal} />
          </Modal>
        </div>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};

export default LoginPage;
