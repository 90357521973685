import { useState, useEffect } from "react";
import { createPortal } from "react-dom";

const Navbar = ({ show, children, onClose }) => {
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  useEffect(() => {
    const onEscHandler = (event) => {
      if (event.code === "Escape") {
        onClose();
      }
    };
    window.addEventListener("keydown", onEscHandler);

    return () => {
      window.removeEventListener("keydown", onEscHandler);
    };
  }, [onClose]);

  const handleOverlayClick = (event) => {
    if (event.currentTarget === event.target) {
      onClose();
    }
  };

  const navbarContent = show ? (
    <nav
      className="fixed"
      onClick={handleOverlayClick}
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        padding: 24,
        top: 75,
        left: 0,
        backgroundColor: "#FFF",
        zIndex: 12
      }}
    >
      {children}
    </nav>
  ) : null;

  if (isBrowser) {
    return createPortal(navbarContent, document.body);
  } else {
    return null;
  }
};

export default Navbar;
