import { Snackbar } from "@mui/material";
import React, { useState } from "react";
import { tabsModal } from "../../static/consts";
import CopyIcon from "../../static/images/copy.svg";
import { useTranslation } from "react-i18next";

const InvestSelectModal = ({ open }) => {
  const [value, setValue] = useState("translate");
  const [isSnack, setIsSnack] = useState(false);
  const { t } = useTranslation();

  const handleChange = (val) => {
    setValue(val);
  };

  const copyText = () => {
    navigator.clipboard.writeText("0xe78A2a0Bd7ff5892C6923A995B259A7829479Bbc");
    setIsSnack(true);
  };

  return (
    <div
      className={`w-600 overflow-auto px-4 sm:px-7 h-96 sm:h-auto py-3 sm:py-5 pb-10 transition-all bg-white rounded-lg `}
    >
      <div className=" mt-1 sm:mt-4 mb-2 sm:mb-4 flex items-center gap-2 sm:gap-4">
        {tabsModal.map((tab, index) => (
          <div
            key={index}
            onClick={() => handleChange(tab.label)}
            className={`text-xs sm:text-base cursor-pointer px-0 pb-2 sm:pb-4 border-b-2 border-transparent capitalize sm:uppercase ${
              value === tab.label && "border-indigo-600"
            }`}
          >
            {t(`invest_modal_transfer_${index + 1}`)}
          </div>
        ))}
      </div>
      {value === "translate" && (
        <div>
          <p className="text-sm sm:text-xl font-bold">
            {t("invest_modal_transfer_myself_title")}
          </p>

          <div className="flex  mt-1 sm:mt-3">
            <span className="text-sm sm:text-lg font-bold">1.</span>
            <p className="text-sm sm:text-lg pl-1 sm:pl-3">
              {t("invest_modal_transfer_myself_content_1")}
            </p>
          </div>
          <div className=" flex mt-1 sm:mt-3" v>
            <span className="text-sm sm:text-lg font-bold">2.</span>
            <p className="text-sm sm:text-lg pl-1 sm:pl-3">
              {t("invest_modal_transfer_myself_content_2")}
              <button
                className="group font-bold relative ml-1 hover:text-linearBlue transition-all "
                onClick={copyText}
              >
                0xe78A2a0Bd7ff5892C6923A995B259A7829479Bbc
                <img
                  className="absolute w-4 h-4 -right-6 top-1 opacity-0 fill-cyan-500 transition-all group-hover:opacity-100"
                  src={CopyIcon}
                />
              </button>
            </p>
          </div>

          <Snackbar
            open={isSnack}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={3000}
            onClose={() => setIsSnack(false)}
            message="Счет скопирован в буфер обмена"
          />

          <div className=" mt-1 sm:mt-3">
            <p className="text-sm sm:text-lg pl-1 sm:pl-7 text-red font-bold">
              {t("invest_modal_transfer_myself_content_req_title")}
            </p>
            <p className="text-sm sm:text-lg pl-1 sm:pl-7 text-red italic">
              {t("invest_modal_transfer_myself_content_req_content")}
            </p>
          </div>

          <div className=" flex   mt-1 sm:mt-3">
            <span className="text-sm sm:text-lg font-bold">3.</span>
            <p className="text-sm sm:text-lg pl-1 sm:pl-3">
              {t("invest_modal_transfer_myself_content_3")}
            </p>
          </div>
          <div className=" flex  mt-1 sm:mt-3">
            <span className="text-sm sm:text-lg font-bold">4.</span>
            <p className="text-sm sm:text-lg pl-1 sm:pl-3">
              {t("invest_modal_transfer_myself_content_4")}
            </p>
          </div>
          <p className="text-sm sm:text-lg pl-4 sm:pl-7 mt-2">
            {t("invest_modal_transfer_myself_content_feedback")}
          </p>
        </div>
      )}

      {/* ------------------------------------------------------------ */}
      {value === "help" && (
        <div value={value} index={1}>
          <p className="text-sm sm:text-lg font-bold">
            {t("invest_modal_transfer_help_content")}
          </p>

          <p className="text-sm sm:text-lg font-bold mt-1 sm:mt-4">
            {t("invest_modal_transfer_help_title")}
          </p>

          <div className=" flex mt-1 sm:mt-3">
            <span className="text-sm sm:text-lg font-bold">1.</span>
            <p className="text-sm sm:text-lg pl-1 sm:pl-3">
              {t("invest_modal_transfer_help_content_1")}
            </p>
          </div>
          <div className=" flex   mt-1 sm:mt-3" v>
            <span className="text-sm sm:text-lg font-bold">2.</span>
            <p className="text-sm sm:text-lg pl-1 sm:pl-3">
              {t("invest_modal_transfer_help_content_2")}
            </p>
          </div>
          <div className=" flex mt-1 sm:mt-3">
            <span className="text-sm sm:text-lg font-bold">3.</span>
            <p className="text-sm sm:text-lg pl-1 sm:pl-3">
              {t("invest_modal_transfer_help_content_3")}
            </p>
          </div>

          <p className="text-sm sm:text-lg pl-3 sm:pl-7 mt-2">
            {t("invest_modal_transfer_help_content_4")}
          </p>

          <p className="text-sm sm:text-lg pl-3 sm:pl-7 mt-2 text-red italic">
            {t("invest_modal_transfer_help_content_5")}
          </p>
        </div>
      )}

      {value === "do" && (
        <div>
          <p className="text-sm sm:text-lg pl-1 mt-2">
            {t("invest_modal_transfer_for_me_title")}
          </p>
          <p className="text-sm sm:text-lg pl-1 mt-1 sm:mt-2">
            <span className="font-bold">
              {t("invest_modal_transfer_for_me_content_bold")}{" "}
            </span>{" "}
            {t("invest_modal_transfer_for_me_content")}
          </p>
          <p className="text-sm sm:text-lg pl-1 mt-1 sm:mt-2">
            <span className="font-bold">
              {t("invest_modal_transfer_for_me_content_bold_1")}{" "}
            </span>
            {t("invest_modal_transfer_for_me_content_1")}
          </p>
        </div>
      )}
    </div>
  );
};

export default InvestSelectModal;
