import { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { Close } from "./CloseModalButton";

const Modal = ({ show, children, onClose }) => {
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  useEffect(() => {
    const onEscHandler = (event) => {
      if (event.code === "Escape") {
        onClose();
      }
    };
    window.addEventListener("keydown", onEscHandler);

    return () => {
      window.removeEventListener("keydown", onEscHandler);
    };
  }, [onClose]);

  const handleOverlayClick = (event) => {
    if (event.currentTarget === event.target) {
      onClose();
    }
  };

  const modalContent = show ? (
    <div
      className="fixed inset-0 z-40 bg-black bg-opacity-80 flex justify-center items-center"
      onClick={handleOverlayClick}
    >
      <div
        className="ml-4 mr-4 relative mx-auto bg-white bg-opacity-100 rounded-xl"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          aria-label="Close modal"
          title="Close modal"
          type="button"
          onClick={onClose}
          style={{
            position: "absolute",
            borderRadius: 25,
            height: 45,
            width: 45,
            padding: 10,
            top: -10,
            right: -10,
            backgroundColor: "#EDEDF4"
          }}
        >
          <Close />
        </button>
        {children}
      </div>
    </div>
  ) : null;

  if (isBrowser) {
    return createPortal(modalContent, document.body);
  } else {
    return null;
  }
};

export default Modal;
