import InvestCardForm from "./InvestCardForm";
import Modal from "../Modals/Modal";
import { useTranslation } from "react-i18next";
import CallService from "../../services/CallService";
import { useState } from "react";
import InvestCardInfo from "./InvestCardInfo";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { Button, Snackbar } from "@mui/material";

const InvestCard = ({ name, image, item }) => {
  const [cardInfo, setCardInfo] = useState(null);
  const [modal, setModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSnack, setIsSnack] = useState(false);
  const { t } = useTranslation();

  const handleClick = async () => {
    const data = await CallService.GetCardInfo(name);

    if (window.innerWidth < 768) {
      setIsModalOpen(!isModalOpen);
    } else {
      setModal(!modal);
    }

    if (data) {
      setCardInfo(data);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const copyHash = () => {
    navigator.clipboard.writeText("0xe78A2a0Bd7ff5892C6923A995B259A7829479Bbc");
    setIsSnack(true);
  };

  return (
    <div className="md:w-80 h-auto bg-white rounded-lg shadow mx-auto pb-2 mb-5 ">
      <div className="w-11/12 mx-auto">
        <div className="pt-4 mb-4">
          <img
            src={image}
            alt="mobile"
            className="lg:h-44 w-full mx-auto rounded-lg shadow"
          />
        </div>
        <div className="mb-4 relative ">
          <p className="text-center text-blue text-xl font-bold">{name}</p>
          {name === "Staking" && (<>
            <div className=" absolute right-0 top-1">
              <Button
                variant="contained"
                className="btn-gradient"
                // onClick={copyHash}
                disable={true}
                style={{
                  padding: "2px 10px",
                  borderRadius: 2,
                  height: 20,
                  width: "100%",
                  backgroundColor: "#ccc",
                  fontSize: "13px",
                  textTransform: "none",
                  cursor:"not-allowed"
                }}
              >
                {t("wallet_address")}
              </Button>
              <Snackbar
                open={isSnack}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                autoHideDuration={3000}
                onClose={() => setIsSnack(false)}
                message="Счет скопирован в буфер обмена"
              />
            </div>
            <p className="bg-red-500 animate-blink text-white py-2 text-xs text-center mt-2 rounded-md ">{t("investment_warning")}</p>
            <div className="  flex items-center justify-center text-gray text-sm">{t("new_staking_platform")}<a className=" text-red" title="Ethical Node" href={'https://ethicalnode.com'} >{" "}EthicalNode.com</a></div>
            </>
          )}
        </div>
        <InvestCardForm name={name} /> 

        {item.link ? (
          <Link to="/investments">
            <button
              className={
                "font-bold cursor-pointer block mx-auto m-4 text-blue hover:text-green" +
                (modal ? " text-green" : "")
              }
              value={name}
            >
              {t("more_info")}
            </button>
          </Link>
        ) : (
          <button
            className={
              "font-bold cursor-pointer block mx-auto m-4 text-blue hover:text-green" +
              (modal ? " text-green" : "")
            }
            onClick={handleClick}
            value={name}
          >
            {t("more_info")}
            <span
              className="icon-caret icon-caret-md"
              style={{ transform: modal ? "" : "rotate(180deg)" }}
            >
              &#8963;
            </span>
          </button>
        )}
      </div>

      <Modal show={isModalOpen} onClose={closeModal}>
        <InvestCardInfo
          cardInfo={cardInfo}
          name={name}
          closeModal={closeModal}
        />
      </Modal>

      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <InvestCardInfo cardInfo={cardInfo} name={name} modal={modal} />
      </Box>
    </div>
  );
};

export default InvestCard;
