import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const ExitModal = ({ onClick }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const logout = () => {
    localStorage.removeItem("id");
    localStorage.removeItem("firstName");
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    navigate("/login");
  };

  return (
    <div className="text-center w-full h-64">
      <div className="w-11/12 mx-auto">
        <div className="w-4/6 mx-auto">
          <h2 className="text-blue font-bold text-xl pt-8 pb-4">
            {t("logout")}
          </h2>
          <p className="text-blue font-normal text-sm pb-8">
            {t("success_logout")}
          </p>
        </div>
        <div className="flex mx-4">
          <Button
            variant="contained"
            onClick={onClick}
            style={{
              borderRadius: 4,
              height: 60,
              width: 313,
              marginRight: 20,
              backgroundColor: "#EDEDF4",
              fontSize: "16px",
              textTransform: "none",
              color: "#191D43",
              fontWeight: "600",
              display: "inline-block",
            }}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={logout}
            style={{
              borderRadius: 4,
              height: 60,
              width: 150,
              backgroundColor: "#4BCBC8",
              fontSize: "16px",
              textTransform: "none",
              color: "#FFF",
              fontWeight: "600",
              display: "inline-block",
            }}
          >
            {t("logout")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ExitModal;
